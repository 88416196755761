import { BaseSvgProps } from "./types";
const SvgValidationCheckCircle = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="m6.964 12.77 2.795 3.138a.77.77 0 0 0 .605.265.801.801 0 0 0 .6-.287l6.54-7.818m4.9 3.932c0 5.746-4.658 10.404-10.404 10.404-5.746 0-10.404-4.658-10.404-10.404C1.596 6.254 6.254 1.596 12 1.596c5.746 0 10.404 4.658 10.404 10.404Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgValidationCheckCircle;
