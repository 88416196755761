import { BaseSvgProps } from "./types";
const SvgCloseCircle = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="m15.393 8.607-6.786 6.786m0-6.786 6.786 6.786M22.403 12c0 5.746-4.657 10.404-10.403 10.404S1.596 17.746 1.596 12C1.596 6.254 6.254 1.596 12 1.596c5.746 0 10.404 4.658 10.404 10.404Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCloseCircle;
