import React from "react";

export type ModalFooterProps = {
  children?: React.ReactNode;
  className?: string;
};

export const ModalFooter: React.FC<ModalFooterProps> = ({
  children,
  className,
}) => {
  return <footer className={className}>{children}</footer>;
};
