"use client";
import { Switch as HeadleassSwitch } from "@headlessui/react";
import classNames from "classnames";
import React, { Dispatch, SetStateAction } from "react";

export type SwitchProps = {
  enabled: boolean;
  setEnabled?: Dispatch<SetStateAction<boolean>> | ((checked: boolean) => void);
  name?: string;
  value?: string;
} & React.HTMLAttributes<HTMLElement>;

const Switch = ({
  enabled,
  setEnabled,
  className,
  name,
  value,
}: SwitchProps) => (
  <HeadleassSwitch
    checked={enabled}
    onChange={setEnabled}
    className={classNames(
      enabled ? "bg-positive" : "bg-neutral-500",
      "duration-400 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-positive focus-visible:ring-offset-2",
      className,
    )}
    name={name}
    value={value}
  >
    <span
      aria-hidden="true"
      className={classNames(
        enabled ? "translate-x-[calc(100%-4px)]" : "translate-x-0",
        "duration-400 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition ease-in-out",
      )}
    />
  </HeadleassSwitch>
);

export { Switch };
