"use client";
import classnames from "classnames";
import React, {
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";

const baseClassName = `
  ease-in-out
  text-base
  rounded
  transition duration-400
  px-4
  py-3
  border
  border-neutral-500
  bg:neutral-50  
  font-normal
  font-ui
  text-neutral-900
  hover:shadow-[0_1px_2px_rgba(38,38,38,0.15),_0px_4px_15px_rgba(38,38,38,0.15)]
  w-[100%]
  h-11
  focus-visible:outline-offset-transparent
  focus-visible:outline-offset-[3px]
  focus-visible:outline-2
  focus-visible:outline
  focus-visible:outline-invert-primary
  disabled:bg-neutral-100
`;

export type TextareaProps = {
  children?: React.ReactNode;
  className?: string;
} & React.HTMLProps<HTMLTextAreaElement>;

export type TextareaRef = {
  getValue: () => string | undefined;
  setValue: (value: string) => void;
  clearValue: () => void;
  getValidity: () => ValidityState | undefined;
};

const Textarea = forwardRef<TextareaRef, TextareaProps>(
  ({ children, className, ...other }, customRef) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const getValue = useCallback(() => inputRef?.current?.value, []);

    const setValue = useCallback((value: string) => {
      if (inputRef && inputRef.current) {
        inputRef.current.value = value;
      }
    }, []);

    const clearValue = useCallback(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.value = "";
      }
    }, []);

    const getValidity = useCallback(() => {
      return inputRef?.current?.validity;
    }, []);

    useImperativeHandle(
      customRef,
      () => ({ getValue, setValue, clearValue, getValidity }),
      [],
    );

    return (
      <textarea
        // @ts-ignore
        ref={inputRef}
        className={classnames(baseClassName, className)}
        {...other}
      />
    );
  },
);

export { Textarea };
