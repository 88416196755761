"use client";
import { Disclosure } from "@headlessui/react";
import classnames from "classnames";

export type StateContainerProps = {
  closedState?: React.ReactNode;
  children?: React.ReactNode;
  overflow?: boolean;
  open: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const StateContainer = ({
  closedState,
  overflow,
  children,
  open,
}: StateContainerProps) => {
  return (
    <Disclosure>
      <Disclosure.Panel
        static
        className={classnames(
          "transition-[max-height,opacity] duration-300 ease-in-out",
          {
            "max-h-[9999px] opacity-100": open,
            "max-h-0 !overflow-hidden opacity-0": !open,
          },
          overflow ? "overflow-visible" : "overflow-hidden",
        )}
      >
        {children}
      </Disclosure.Panel>
      <Disclosure.Panel
        static
        className={classnames(
          "transition-[max-height,opacity] duration-300 ease-in-out",
          {
            "max-h-[9999px] opacity-100": !open,
            "max-h-0 !overflow-hidden opacity-0": open,
          },
          overflow ? "overflow-visible" : "overflow-hidden",
        )}
      >
        {closedState}
      </Disclosure.Panel>
    </Disclosure>
  );
};
