import { Dialog } from "@headlessui/react";
import classnames from "classnames";
import React from "react";

export type ModalContentProps = {
  children?: React.ReactNode;
  className?: string;
  element?: React.ElementType;
};

export const ModalContent: React.FC<ModalContentProps> = ({
  children,
  className,
  element = "div",
}) => {
  return (
    <Dialog.Description as={element} className={classnames(className)}>
      {children}
    </Dialog.Description>
  );
};
