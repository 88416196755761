import { BaseSvgProps } from "./types";
const SvgCalendarAdd = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M3.235 4.829a1.594 1.594 0 0 0-1.594 1.593v14.343a1.593 1.593 0 0 0 1.594 1.594h17.53a1.594 1.594 0 0 0 1.594-1.594V6.422a1.594 1.594 0 0 0-1.594-1.593h-3.187M6.422 1.64v6.375M17.578 1.64v6.375M6.422 4.829h7.968m1.594 8.765H8.016M12 9.61v7.968"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCalendarAdd;
