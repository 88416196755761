"use client";
import { Menu } from "@headlessui/react";
import classnames from "classnames";
import { Button } from "../Button";
import { CalendarAdd } from "../Icons";

export type AddToCalendarProps = {
  startTime: Date;
  endTime: Date;
  title: string;
  description: string;
  location: string;
  iconOnly?: boolean;
};

const formatDateTime = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = pad(date.getUTCMonth() + 1);
  const day = pad(date.getUTCDate());
  const hour = pad(date.getUTCHours());
  const minute = pad(date.getUTCMinutes());
  const second = pad(date.getUTCSeconds());
  return `${year}${month}${day}T${hour}${minute}${second}Z`;
};

const pad = (i: number) => {
  return i < 10 ? `0${i}` : `${i}`;
};

export const AddToCalendar: React.FC<AddToCalendarProps> = ({
  startTime,
  endTime,
  title,
  description,
  iconOnly,
  location,
}) => {
  return (
    <div className="relative inline-block">
      <Menu>
        <Menu.Button
          className={classnames(
            Button.classes.baseClassName,
            iconOnly
              ? Button.classes.classNames.primary
              : `${Button.classes.classNames.secondary_black} ${Button.classes.classNames.secondary}`,
            Button.classes.classNames.medium,
            "flex  items-center justify-center  whitespace-nowrap align-middle",

            {
              //    [Button.classes.classNames.iconOnlyClassName]: iconOnly,
              ["relative flex !w-fit items-center "]: !iconOnly,
              ["!h-[32px] !w-[32px]"]: iconOnly,
            },
          )}
        >
          <span className={!iconOnly ? "h-4 w-4" : ""}>
            <CalendarAdd
              width={!iconOnly ? "100%" : 14}
              height={!iconOnly ? "100%" : 14}
              strokeWidth={2}
            />
          </span>
          {!iconOnly && (
            <span className="ml-2.5  capitalize">Add to calendar</span>
          )}
        </Menu.Button>
        <Menu.Items className="absolute z-10 mt-1 max-h-60 w-full min-w-[200px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus-visible:outline-none sm:text-sm">
          <Menu.Item>
            {({ active }) => (
              <AddToDefaultCalendar
                className={`${
                  active ? "bg-brand  text-white" : "text-gray-900"
                } group flex w-full items-center rounded-md  py-2 pl-3 pr-9 text-sm hover:bg-neutral-100`}
                startTime={startTime}
                endTime={endTime}
                title={title}
                description={description}
                location={location}
              >
                Apple
              </AddToDefaultCalendar>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <AddToGoogleCalender
                className={`${
                  active ? "bg-brand  text-white" : "text-gray-900"
                } group flex w-full items-center rounded-md  py-2 pl-3 pr-9 text-sm hover:bg-neutral-100`}
                startTime={startTime}
                endTime={endTime}
                title={title}
                description={description}
                location={location}
              >
                Google
              </AddToGoogleCalender>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <AddToOffice365Calender
                className={`${
                  active ? "bg-brand  text-white" : "text-gray-900"
                } group flex w-full items-center rounded-md  py-2 pl-3 pr-9 text-sm hover:bg-neutral-100`}
                startTime={startTime}
                endTime={endTime}
                title={title}
                description={description}
                location={location}
              >
                Office 365
              </AddToOffice365Calender>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <AddToOutlookCalender
                className={`${
                  active ? "bg-brand  text-white" : "text-gray-900"
                } group flex w-full items-center rounded-md  py-2 pl-3 pr-9 text-sm hover:bg-neutral-100`}
                startTime={startTime}
                endTime={endTime}
                title={title}
                description={description}
                location={location}
              >
                Outlook.com
              </AddToOutlookCalender>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <AddToDefaultCalendar
                className={`${
                  active ? "bg-brand  text-white" : "text-gray-900"
                } group flex w-full items-center rounded-md  py-2 pl-3 pr-9 text-sm hover:bg-neutral-100`}
                startTime={startTime}
                endTime={endTime}
                title={title}
                description={description}
                location={location}
              >
                iCal File
              </AddToDefaultCalendar>
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>
  );
};

export const AddToGoogleCalender: React.FC<
  AddToCalendarProps &
    React.HTMLProps<HTMLAnchorElement> & { children: React.ReactNode }
> = ({
  startTime,
  endTime,
  title,
  description,
  location,
  className,
  children,
}) => {
  const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${formatDateTime(
    startTime,
  )}/${formatDateTime(endTime)}&details=${description}&location=${location}`;
  return (
    <a target="_blank" className={className} href={url}>
      {children}
    </a>
  );
};

export const AddToOffice365Calender: React.FC<
  AddToCalendarProps &
    React.HTMLProps<HTMLAnchorElement> & { children: React.ReactNode }
> = ({
  startTime,
  endTime,
  title,
  description,
  location,
  className,
  children,
}) => {
  const url = `https://outlook.office.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&startdt=${encodeURIComponent(
    startTime.toISOString(),
  )}&enddt=${encodeURIComponent(endTime.toISOString())}
  &subject=${title}&body=${description}&location=${location}
  &allday=false&uid=${encodeURIComponent(`submix-${title}`)}&isutc=true&online=1
  `;
  return (
    <a target="_blank" className={className} href={url}>
      {children}
    </a>
  );
};

export const AddToOutlookCalender: React.FC<
  AddToCalendarProps &
    React.HTMLProps<HTMLAnchorElement> & { children: React.ReactNode }
> = ({
  startTime,
  endTime,
  title,
  description,
  location,
  className,
  children,
}) => {
  const url = `https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&startdt=${encodeURIComponent(
    startTime.toISOString(),
  )}&enddt=${encodeURIComponent(endTime.toISOString())}
  &subject=${title}&body=${description}&location=${location}
  &allday=false&uid=${"123"}&isutc=true&online=1
  `;
  return (
    <a target="_blank" className={className} href={url}>
      {children}
    </a>
  );
};

export const AddToDefaultCalendar: React.FC<
  AddToCalendarProps &
    React.HTMLProps<HTMLAnchorElement> & { children: React.ReactNode }
> = ({
  startTime,
  endTime,
  title,
  description,
  location,
  className,
  children,
}) => {
  const url = `data:text/calendar;charset=utf8,${encodeURIComponent(`
BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
URL:${location}
DTSTART:${formatDateTime(startTime)}
DTEND:${formatDateTime(endTime)}
SUMMARY:${title}
DESCRIPTION:${description}
LOCATION:${location}
END:VEVENT
END:VCALENDAR
  `)}`;
  return (
    <>
      <a
        target="_blank"
        className={className}
        download={`${title}.ics`}
        href={url}
      >
        {children}
      </a>
    </>
  );
};
