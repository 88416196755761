"use client";
import React from "react";
import classnames from "classnames";
import { Typography } from "../Typography";

export type PanelTitleProps = {
  icon?: React.ReactNode;
  children: React.ReactNode;
  classNames?: classnames.ArgumentArray;
} & React.HTMLAttributes<HTMLElement>;

const PanelTitle = ({ icon, children, classNames }: PanelTitleProps) => {
  return (
    <div className={classnames("flex items-center gap-2.5 pb-7", classNames)}>
      {icon && <div className="h-6 w-6">{icon}</div>}
      <Typography.Body className="pt-[2px] text-lg !font-bold !leading-7">
        {children}
      </Typography.Body>
      {/*   <div className="absolute top-full z-50 h-14 w-full bg-gradient-to-b from-white to-transparent content-none" /> */}
    </div>
  );
};

export { PanelTitle };
