"use client";

import React from "react";
import { useCurrentSliderStep } from "../../../hooks/useCurrentSliderStep";
import DesktopSliderControls from "./DesktopSliderControls";

type DesktopSliderProps = {
  itemsCount: number;
  children: React.ReactNode[];
  onDark?: boolean;
};

export const ITEM_MIN_WIDTH_IN_PX = 480;

const DesktopSlider = ({
  itemsCount,
  children,
  onDark,
}: DesktopSliderProps) => {
  const { currentSlideStep, nextSlideStep, prevSlideStep } =
    useCurrentSliderStep(0, itemsCount);

  return (
    <>
      <div
        className="col-span-11 col-start-2 overflow-hidden"
        style={{
          maskImage:
            "linear-gradient(90deg, rgb(0, 0, 0) 84%, transparent 100%)",
        }}
      >
        <div
          className="grid w-fit grid-rows-1 gap-7 overflow-visible transition-transform duration-500 ease-in-out"
          style={{
            width: `calc(${children.length * ((100 / 11) * 5)}% + 28px)`,
            minWidth: `${children.length * ITEM_MIN_WIDTH_IN_PX}px`,
            gridTemplateColumns: `repeat(${children.length * 5}, 1fr)`,
            transform: currentSlideStep
              ? `translateX(calc(-${
                  currentSlideStep * (100 / children.length)
                }% - ${currentSlideStep * 6}px))`
              : "translateX(0)",
          }}
        >
          {children}
        </div>
      </div>
      <DesktopSliderControls
        prevSlideStep={prevSlideStep}
        nextSlideStep={nextSlideStep}
        currentSlideStep={currentSlideStep}
        itemsCount={itemsCount}
        onDark={onDark}
      />
    </>
  );
};

export default DesktopSlider;
