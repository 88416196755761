"use client";
import classnames from "classnames";
import React, {
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Tag } from "../Typography/Tag";

const baseClassName = `
  ease-in-out
  transition duration-400
  px-3
  py-1.5
  border
  rounded-full
  select-none
  cursor-pointer
  whitespace-nowrap
`;
const onDarkClassName = `
bg-neutral-900
text-primary
border-neutral-50
peer-checked:text-primary
peer-checked:text-neutral-900
hover:text-neutral-900
hover:text-primary
hover:border-neutral-50
`;

const onLightClassName = `
bg-neutral-50
text-neutral-900
border-neutral-900
peer-checked:bg-neutral-900
peer-checked:text-neutral-50
hover:!bg-neutral-900
hover:text-neutral-50
`;

export type PillProps = {
  children?: React.ReactNode;
  className?: string;
  isRadio?: boolean;
  value?: string;
  name?: string;
  onDark?: boolean;
} & React.HTMLAttributes<HTMLElement>;

export type PillRef = {
  getValue: () => string | undefined;
  getChecked: () => boolean | undefined;
  setValue: (value: string) => void;
  clearValue: () => void;
};

const Pill = forwardRef<PillRef, PillProps>(
  (
    { children, onDark, className, isRadio, value, name, ...other },
    customRef,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const getValue = useCallback(() => inputRef?.current?.value, []);
    const getChecked = useCallback(() => inputRef?.current?.checked, []);

    const setValue = useCallback((value: string) => {
      if (inputRef && inputRef.current) {
        inputRef.current.value = value;
      }
    }, []);

    const clearValue = useCallback(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.value = "";
      }
    }, []);

    useImperativeHandle(
      customRef,
      () => ({ getValue, setValue, clearValue, getChecked }),
      [getValue, setValue, clearValue, getChecked],
    );

    return (
      <label className="flex min-h-8">
        <input
          ref={inputRef}
          className={classnames("peer invisible h-0 w-0")}
          type={!isRadio ? "checkbox" : "radio"}
          value={value}
          name={name}
          {...other}
        />
        <Tag
          className={classnames(className, baseClassName, {
            [onLightClassName]: !onDark,
            [onDarkClassName]: onDark,
          })}
        >
          {children}
        </Tag>
      </label>
    );
  },
);

export { Pill };
