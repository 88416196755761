"use client";

import classnames from "classnames";
import React from "react";
import { Button, Typography } from "../..";
import { TabPanel, TabPanelProps } from "./TabPanel";

export type TabProps = {
  color: "black" | "white" | "green";
  disabled?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  tabIndex?: string;
  label?: string;
  selected?: boolean;
  selectedClassName?: string;
  selectedTabIndicatorColor?: "black" | "white" | "green";
  children?: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export type TabStatic = {
  Panel: React.FC<TabPanelProps>;
};

const selectedTabIndicatorBaseClassNames =
  "absolute bottom-0 left-0 h-[1.5px] w-full";

export const Tab: React.FC<TabProps> & TabStatic = ({
  selected,
  selectedClassName = "",
  onClick,
  label,
  selectedTabIndicatorColor,
  children,
  ...restOfProps
}) => {
  const { disabled, color } = restOfProps;

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  return (
    <div>
      <Button
        className={classnames(
          "relative !border-0 border-transparent  !px-0 !py-0 focus-within:border-transparent focus:border-transparent focus-visible:!border-2 focus-visible:border-neutral-900 ",
          { "focus-visible:border-transparent": selected },
        )}
        variant="tertiary"
        noBorder
        onClick={handleOnClick}
        role="tab"
        aria-selected={selected || "false"}
        {...restOfProps}
      >
        <Typography.Body
          className={classnames(
            "mb-[2px] mt-[2px] !font-bold ",
            selected && selectedClassName,
          )}
        >
          {label}
        </Typography.Body>
        {selected && (
          <span
            className={classnames(selectedTabIndicatorBaseClassNames, {
              "bg-neutral-900": selectedTabIndicatorColor
                ? selectedTabIndicatorColor === "black"
                : color === "black",
              "bg-neutral-50": selectedTabIndicatorColor
                ? selectedTabIndicatorColor === "white"
                : color === "white",
              "bg-brand": selectedTabIndicatorColor
                ? selectedTabIndicatorColor === "green"
                : color === "green",
            })}
          />
        )}
      </Button>
      {selected && children}
    </div>
  );
};

Tab.Panel = TabPanel;
