import { ReactNode } from "react";
import { Typography } from "../../Typography";
import classnames from "classnames";

export const PlanPrice = ({
  price,
  interval,
  description,
  onDark,
  classNames,
  smallPrice = false,
  intervalAbovePrice = false,
}: {
  price: string;
  interval: string | ReactNode;
  description?: string | ReactNode;
  onDark?: boolean;
  classNames?: string;
  smallPrice?: boolean;
  intervalAbovePrice?: boolean;
}) => {
  return (
    <div className={classnames(classNames)}>
      <div
        className={classnames(
          "flex gap-0",
          smallPrice ? "items-baseline" : "items-end",
          {
            "flex-col-reverse !items-start min-[500px]:!items-end":
              intervalAbovePrice,
            "mb-5": !description,
          },
        )}
      >
        <Typography.Body
          className={classnames(
            "!font-headings  !font-bold !leading-9",
            smallPrice ? "!text-xl" : "!text-3xl",
            {
              "!text-primary": onDark,
              "!text-invert-primary": !onDark,
            },
          )}
        >
          {price}
        </Typography.Body>
        <Typography.Body
          className={classnames("min-h-5 pb-[2px] !text-sm", {
            "!text-primary": onDark,
            "!text-invert-secondary": !onDark,
          })}
        >
          {interval}
        </Typography.Body>
      </div>
      {description && (
        <Typography.Body
          className={classnames(
            "!text-sm",
            {
              "!text-primary": onDark,
              "!text-invert-secondary": !onDark,
            },
            {
              "[&_strong]:!text-invert-primary": !onDark,
            },
          )}
        >
          {description}
        </Typography.Body>
      )}
    </div>
  );
};
