import classnames from "classnames";
import { Disclosure } from "@headlessui/react";
import { BaseTypography } from "../Typography/types";

export type AccordionButtonProps = {
  icon?: React.ReactNode;
} & BaseTypography;

const baseClassName = `
flex
items-center
w-full
justify-between
p-5
text-left
font-bold
bg-transparent
hover:!bg-neutral-300
rounded
ui-open:bg-[inherit]
`;

const onDarkClassName = `text-neutral-50`;
const onLightClassName = `text-neutral-900`;

const baseTransition = `
duration-400 
transition
ease-in-out
`;

const AccordionButton: React.FunctionComponent<AccordionButtonProps> = ({
  className,
  icon,
  children,
  onDark,
}) => {
  return (
    <Disclosure.Button
      className={classnames(className, baseClassName, baseTransition, {
        [onDarkClassName]: onDark,
        [onLightClassName]: !onDark,
      })}
    >
      {children}
      {icon && (
        <span
          className={classnames(
            "ml-5 ui-open:rotate-180 ui-open:transform ui-not-open:rotate-0",
            baseTransition,
          )}
        >
          {icon}
        </span>
      )}
    </Disclosure.Button>
  );
};
export { AccordionButton };
