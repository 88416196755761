import classnames from "classnames";

export type LogoBaseLightProps = {
  size: "small" | "medium" | "large";
  type: "primary" | "secondary" | "tertiary";
};

const LogoBaseLight = () => (
  <svg viewBox="0 0 339 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.0531 17.8241C35.4366 16.0221 34.4645 14.7418 33.1367 13.983C31.8564 13.2243 30.007 12.8449 27.5885 12.8449C25.1701 12.8449 23.297 13.1295 21.9692 13.6985C20.6888 14.2201 20.0486 15.0026 20.0486 16.0458C20.0486 16.8046 20.3332 17.3736 20.9022 17.753C21.5187 18.1323 22.6331 18.4643 24.2454 18.7488L35.7686 20.6693C42.6446 21.8074 47.5526 23.6331 50.4927 26.1464C53.4802 28.6123 54.9739 32.1451 54.9739 36.7449C54.9739 48.031 45.9877 53.6741 28.0153 53.6741C10.9439 53.6741 1.86284 47.5331 0.772171 35.2512H18.7683C19.527 39.1871 22.6331 41.155 28.0864 41.155C33.9192 41.155 36.8355 39.8984 36.8355 37.3851C36.8355 36.579 36.4325 35.9625 35.6263 35.5357C34.8676 35.0615 33.6347 34.6821 31.9275 34.3976L22.7516 32.975C15.7334 31.8843 10.5408 30.0112 7.17395 27.3556C3.80709 24.6527 2.12366 21.0487 2.12366 16.5438C2.12366 11.4698 4.42356 7.51013 9.02336 4.66489C13.6706 1.77224 20.0961 0.325905 28.2998 0.325905C35.9346 0.325905 41.8621 1.67739 46.0826 4.38037C50.3504 7.03592 52.8163 11.0193 53.4802 16.3304L36.0531 17.8241Z"
      fill="#7101A4"
    />
    <path
      d="M94.7833 1.60626H113.135V29.4896C113.135 37.7408 110.906 43.8343 106.449 47.7702C102.039 51.7061 95.2101 53.6741 85.9631 53.6741C76.7161 53.6741 69.8638 51.7061 65.4063 47.7702C60.9962 43.8343 58.7911 37.7408 58.7911 29.4896V1.60626H77.1429V30.9833C77.1429 33.7811 77.8305 35.8202 79.2057 37.1006C80.5809 38.3809 82.8333 39.0211 85.9631 39.0211C89.0929 39.0211 91.3453 38.3809 92.7205 37.1006C94.0957 35.8202 94.7833 33.7811 94.7833 30.9833V1.60626Z"
      fill="#7101A4"
    />
    <path
      d="M120.561 52.3937V1.60626H148.586C163.334 1.60626 170.708 5.99267 170.708 14.7655C170.708 19.555 168.052 23.1115 162.741 25.4351C166.061 26.5258 168.526 28.067 170.139 30.0586C171.798 32.0029 172.628 34.4687 172.628 37.4562C172.628 42.3406 170.684 46.0631 166.796 48.6238C162.955 51.1371 157.335 52.3937 149.938 52.3937H120.561ZM147.875 20.8827C151.621 20.8827 153.494 19.6261 153.494 17.1128C153.494 15.785 153.044 14.8603 152.143 14.3387C151.242 13.7696 149.748 13.4851 147.661 13.4851H137.205V20.8827H147.875ZM148.302 40.5149C150.815 40.5149 152.593 40.1592 153.636 39.4479C154.727 38.7366 155.272 37.5985 155.272 36.0336C155.272 34.4687 154.703 33.3544 153.565 32.6905C152.475 31.9792 150.673 31.6235 148.159 31.6235H137.205V40.5149H148.302Z"
      fill="#7101A4"
    />
    <path
      d="M228.364 52.3937V27.6402L218.121 52.3937H204.962L194.719 27.6402V52.3937H178.288V1.60626H198.702L211.861 32.4059L225.021 1.60626H245.151V52.3937H228.364Z"
      fill="#7101A4"
    />
    <path
      d="M253.103 52.3937V1.60626H271.455V52.3937H253.103Z"
      fill="#7101A4"
    />
    <path
      d="M316.776 52.3937L305.822 37.5274L294.868 52.3937H274.596L294.726 26.0042L276.089 1.60626H297.5L306.178 14.2676L315.425 1.60626H335.697L317.416 25.5062L338.187 52.3937H316.776Z"
      fill="#7101A4"
    />
  </svg>
);

export { LogoBaseLight };
