import classnames from "classnames";

export type LogoLightProps = {
  size: "small" | "medium" | "large";
  type: "primary" | "secondary" | "tertiary";
};

const LogoLight = () => (
  <svg viewBox="0 0 343 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.0119 21.8653C34.3954 20.0633 33.4233 18.783 32.0955 18.0243C30.8152 17.2655 28.9658 16.8862 26.5473 16.8862C24.1289 16.8862 22.2557 17.1707 20.928 17.7397C19.6476 18.2614 19.0074 19.0438 19.0074 20.087C19.0074 20.8458 19.292 21.4148 19.861 21.7942C20.4775 22.1736 21.5919 22.5055 23.2042 22.79L34.7274 24.7106C41.6034 25.8487 46.5114 27.6744 49.4515 30.1876C52.439 32.6535 53.9327 36.1863 53.9327 40.7861C53.9327 52.0723 44.9465 57.7153 26.9741 57.7153C9.90268 57.7153 0.821631 51.5743 -0.269043 39.2924H17.7271C18.4858 43.2283 21.5919 45.1963 27.0452 45.1963C32.878 45.1963 35.7943 43.9396 35.7943 41.4263C35.7943 40.6202 35.3913 40.0037 34.5851 39.5769C33.8264 39.1027 32.5934 38.7233 30.8863 38.4388L21.7104 37.0162C14.6922 35.9255 9.4996 34.0524 6.13274 31.3969C2.76588 28.6939 1.08244 25.0899 1.08244 20.585C1.08244 15.511 3.38234 11.5513 7.98214 8.70611C12.6294 5.81345 19.0549 4.36712 27.2586 4.36712C34.8933 4.36712 40.8209 5.71861 45.0413 8.42158C49.3092 11.0771 51.7751 15.0605 52.439 20.3716L35.0119 21.8653Z"
      fill="#EB0059"
    />
    <path
      d="M93.7421 5.64748H112.094V33.5308C112.094 41.782 109.865 47.8755 105.408 51.8114C100.997 55.7473 94.1689 57.7153 84.9219 57.7153C75.6749 57.7153 68.8226 55.7473 64.3651 51.8114C59.9549 47.8755 57.7499 41.782 57.7499 33.5308V5.64748H76.1017V35.0245C76.1017 37.8224 76.7893 39.8614 78.1645 41.1418C79.5396 42.4222 81.7921 43.0623 84.9219 43.0623C88.0516 43.0623 90.3041 42.4222 91.6793 41.1418C93.0545 39.8614 93.7421 37.8224 93.7421 35.0245V5.64748Z"
      fill="#EB0059"
    />
    <path
      d="M119.519 56.4349V5.64748H147.545C162.293 5.64748 169.667 10.0339 169.667 18.8067C169.667 23.5962 167.011 27.1527 161.7 29.4763C165.019 30.567 167.485 32.1082 169.098 34.0998C170.757 36.0441 171.587 38.51 171.587 41.4975C171.587 46.3818 169.643 50.1043 165.754 52.665C161.913 55.1783 156.294 56.4349 148.896 56.4349H119.519ZM146.834 24.924C150.58 24.924 152.453 23.6673 152.453 21.154C152.453 19.8262 152.002 18.9015 151.101 18.3799C150.2 17.8109 148.707 17.5263 146.62 17.5263H136.164V24.924H146.834ZM147.26 44.5561C149.774 44.5561 151.552 44.2004 152.595 43.4891C153.686 42.7778 154.231 41.6397 154.231 40.0748C154.231 38.51 153.662 37.3956 152.524 36.7317C151.433 36.0204 149.631 35.6647 147.118 35.6647H136.164V44.5561H147.26Z"
      fill="#EB0059"
    />
    <path
      d="M227.323 56.4349V31.6814L217.08 56.4349H203.921L193.678 31.6814V56.4349H177.246V5.64748H197.661L210.82 36.4472L223.979 5.64748H244.11V56.4349H227.323Z"
      fill="#EB0059"
    />
    <path
      d="M252.062 56.4349V5.64748H270.414V56.4349H252.062Z"
      fill="#EB0059"
    />
    <path
      d="M315.735 56.4349L304.781 41.5686L293.827 56.4349H273.554L293.684 30.0454L275.048 5.64748H296.459L305.136 18.3088L314.384 5.64748H334.656L316.375 29.5475L337.145 56.4349H315.735Z"
      fill="#EB0059"
    />
    <path
      d="M41.1355 17.7829C40.519 15.9809 39.5469 14.7006 38.2191 13.9418C36.9388 13.1831 35.0894 12.8037 32.6709 12.8037C30.2525 12.8037 28.3794 13.0883 27.0516 13.6573C25.7712 14.1789 25.1311 14.9614 25.1311 16.0046C25.1311 16.7634 25.4156 17.3324 25.9846 17.7118C26.6011 18.0911 27.7155 18.4231 29.3278 18.7076L40.851 20.6281C47.727 21.7662 52.635 23.5919 55.5751 26.1052C58.5626 28.5711 60.0563 32.1039 60.0563 36.7037C60.0563 47.9898 51.0701 53.6329 33.0977 53.6329C16.0263 53.6329 6.94526 47.4919 5.85458 35.21H23.8507C24.6094 39.1459 27.7155 41.1138 33.1689 41.1138C39.0016 41.1138 41.918 39.8572 41.918 37.3439C41.918 36.5377 41.5149 35.9213 40.7087 35.4945C39.95 35.0203 38.7171 34.6409 37.0099 34.3564L27.834 32.9338C20.8158 31.8431 15.6232 29.97 12.2564 27.3144C8.8895 24.6115 7.20607 21.0075 7.20607 16.5025C7.20607 11.4285 9.50597 7.46892 14.1058 4.62368C18.753 1.73102 25.1785 0.284691 33.3822 0.284691C41.017 0.284691 46.9445 1.63618 51.165 4.33916C55.4328 6.99471 57.8987 10.978 58.5626 16.2891L41.1355 17.7829Z"
      fill="#150EEB"
    />
    <path
      d="M99.8657 1.56505H118.218V29.4484C118.218 37.6996 115.989 43.7931 111.531 47.729C107.121 51.6649 100.293 53.6329 91.0455 53.6329C81.7985 53.6329 74.9462 51.6649 70.4887 47.729C66.0786 43.7931 63.8735 37.6996 63.8735 29.4484V1.56505H82.2253V30.9421C82.2253 33.7399 82.9129 35.779 84.2881 37.0594C85.6633 38.3397 87.9157 38.9799 91.0455 38.9799C94.1753 38.9799 96.4277 38.3397 97.8029 37.0594C99.1781 35.779 99.8657 33.7399 99.8657 30.9421V1.56505Z"
      fill="#150EEB"
    />
    <path
      d="M125.643 52.3525V1.56505H153.669C168.416 1.56505 175.79 5.95145 175.79 14.7243C175.79 19.5137 173.135 23.0703 167.824 25.3939C171.143 26.4846 173.609 28.0257 175.221 30.0174C176.881 31.9617 177.711 34.4275 177.711 37.415C177.711 42.2993 175.766 46.0219 171.878 48.5826C168.037 51.0959 162.418 52.3525 155.02 52.3525H125.643ZM152.957 20.8415C156.703 20.8415 158.577 19.5849 158.577 17.0716C158.577 15.7438 158.126 14.8191 157.225 14.2975C156.324 13.7284 154.83 13.4439 152.744 13.4439H142.288V20.8415H152.957ZM153.384 40.4737C155.897 40.4737 157.676 40.118 158.719 39.4067C159.809 38.6954 160.355 37.5573 160.355 35.9924C160.355 34.4275 159.786 33.3131 158.648 32.6493C157.557 31.9379 155.755 31.5823 153.242 31.5823H142.288V40.4737H153.384Z"
      fill="#150EEB"
    />
    <path
      d="M233.446 52.3525V27.599L223.203 52.3525H210.044L199.801 27.599V52.3525H183.37V1.56505H203.785L216.944 32.3647L230.103 1.56505H250.233V52.3525H233.446Z"
      fill="#150EEB"
    />
    <path
      d="M258.185 52.3525V1.56505H276.537V52.3525H258.185Z"
      fill="#150EEB"
    />
    <path
      d="M321.859 52.3525L310.904 37.4862L299.95 52.3525H279.678L299.808 25.9629L281.172 1.56505H302.582L311.26 14.2264L320.507 1.56505H340.779L322.499 25.465L343.269 52.3525H321.859Z"
      fill="#150EEB"
    />
    <path
      d="M37.0531 19.8241C36.4366 18.0221 35.4645 16.7418 34.1367 15.983C32.8564 15.2243 31.007 14.8449 28.5885 14.8449C26.1701 14.8449 24.2969 15.1295 22.9692 15.6985C21.6888 16.2201 21.0486 17.0026 21.0486 18.0458C21.0486 18.8046 21.3332 19.3736 21.9022 19.753C22.5187 20.1323 23.6331 20.4643 25.2454 20.7488L36.7686 22.6693C43.6446 23.8074 48.5526 25.6331 51.4927 28.1464C54.4802 30.6123 55.9739 34.1451 55.9739 38.7449C55.9739 50.031 46.9877 55.6741 29.0153 55.6741C11.9439 55.6741 2.86283 49.5331 1.77216 37.2512H19.7683C20.527 41.1871 23.6331 43.155 29.0864 43.155C34.9192 43.155 37.8355 41.8984 37.8355 39.3851C37.8355 38.579 37.4325 37.9625 36.6263 37.5357C35.8676 37.0615 34.6346 36.6821 32.9275 36.3976L23.7516 34.975C16.7334 33.8843 11.5408 32.0112 8.17394 29.3556C4.80707 26.6527 3.12364 23.0487 3.12364 18.5438C3.12364 13.4698 5.42354 9.51013 10.0233 6.66489C14.6706 3.77224 21.0961 2.3259 29.2998 2.3259C36.9345 2.3259 42.8621 3.67739 47.0825 6.38037C51.3504 9.03592 53.8163 13.0193 54.4802 18.3304L37.0531 19.8241Z"
      fill="#7101A4"
    />
    <path
      d="M95.7833 3.60626H114.135V31.4896C114.135 39.7408 111.906 45.8343 107.449 49.7702C103.039 53.7061 96.2101 55.6741 86.9631 55.6741C77.7161 55.6741 70.8638 53.7061 66.4062 49.7702C61.9961 45.8343 59.7911 39.7408 59.7911 31.4896V3.60626H78.1429V32.9833C78.1429 35.7811 78.8305 37.8202 80.2057 39.1006C81.5808 40.3809 83.8333 41.0211 86.9631 41.0211C90.0928 41.0211 92.3453 40.3809 93.7205 39.1006C95.0957 37.8202 95.7833 35.7811 95.7833 32.9833V3.60626Z"
      fill="#7101A4"
    />
    <path
      d="M121.56 54.3937V3.60626H149.586C164.334 3.60626 171.708 7.99267 171.708 16.7655C171.708 21.555 169.052 25.1115 163.741 27.4351C167.061 28.5258 169.526 30.067 171.139 32.0586C172.798 34.0029 173.628 36.4687 173.628 39.4562C173.628 44.3406 171.684 48.0631 167.796 50.6238C163.955 53.1371 158.335 54.3937 150.938 54.3937H121.56ZM148.875 22.8827C152.621 22.8827 154.494 21.6261 154.494 19.1128C154.494 17.785 154.044 16.8603 153.143 16.3387C152.242 15.7696 150.748 15.4851 148.661 15.4851H138.205V22.8827H148.875ZM149.302 42.5149C151.815 42.5149 153.593 42.1592 154.636 41.4479C155.727 40.7366 156.272 39.5985 156.272 38.0336C156.272 36.4687 155.703 35.3544 154.565 34.6905C153.475 33.9792 151.673 33.6235 149.159 33.6235H138.205V42.5149H149.302Z"
      fill="#7101A4"
    />
    <path
      d="M229.364 54.3937V29.6402L219.121 54.3937H205.962L195.719 29.6402V54.3937H179.288V3.60626H199.702L212.861 34.4059L226.021 3.60626H246.151V54.3937H229.364Z"
      fill="#7101A4"
    />
    <path
      d="M254.103 54.3937V3.60626H272.455V54.3937H254.103Z"
      fill="#7101A4"
    />
    <path
      d="M317.776 54.3937L306.822 39.5274L295.868 54.3937H275.596L295.726 28.0042L277.089 3.60626H298.5L307.178 16.2676L316.425 3.60626H336.697L318.416 27.5062L339.187 54.3937H317.776Z"
      fill="#7101A4"
    />
  </svg>
);

export { LogoLight };
