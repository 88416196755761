"use client";
import { Listbox as HeadlessListbox } from "@headlessui/react";
import { ChevronArrowUp } from "@submix/component-library";
import classNames from "classnames";
import React, { ReactElement } from "react";
import { MenuItem } from "./Menu";

export type ActionProps = {
  icon: React.ReactNode;
  isMenu?: boolean;
  numberIndicator?: number | string;
  children?: ReactElement;
  onSelectItem?: (item: MenuItem) => void;
  onButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selectedItem?: MenuItem;
  isIdle?: boolean;
  rounded?: boolean;
  isWarning?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Action = ({
  icon,
  isMenu = false,
  numberIndicator,
  children,
  onSelectItem,
  onButtonClick,
  selectedItem,
  isIdle,
  rounded = false,
  isWarning = false,
  ...other
}: ActionProps) => {
  const renderChildren = (open: boolean) => {
    return React.Children.map(children, (child) => {
      return (
        child &&
        React.cloneElement(child, {
          open: open,
        })
      );
    });
  };
  return isMenu ? (
    <HeadlessListbox
      as="div"
      className="relative inline-block text-left"
      horizontal
      onChange={onSelectItem}
      value={selectedItem}
      disabled={other.disabled}
    >
      {({ open }) => (
        <>
          <div
            className={classNames(
              "flex items-center justify-center rounded-full focus-visible:bg-primary-alt",
              {
                "hover:bg-neutral-100 dark:hover:bg-neutral-800":
                  !other.disabled,
              },
              { "rounded-full": rounded },
              {
                "cursor-not-allowed": other.disabled,
              },
            )}
          >
            <HeadlessListbox.Button
              /** @ts-ignore  issue with type definition in package */
              disabled={other.disabled}
              aria-disabled={other.disabled}
            >
              <div
                className={classNames(
                  "absolute right-0 top-0  z-10 hidden h-4 w-4 translate-x-[20%] translate-y-[-20%] items-center justify-center rounded bg-neutral-600 disabled:opacity-30   lg:flex dark:bg-invert-secondary",
                  {
                    " hover:bg-neutral-900 dark:hover:bg-neutral-800":
                      !other.disabled,
                  },
                )}
                aria-disabled={other.disabled}
              >
                <ChevronArrowUp
                  stroke="white"
                  height={12}
                  width={12}
                  strokeWidth={2.5}
                />
              </div>
            </HeadlessListbox.Button>

            {numberIndicator && typeof numberIndicator === "string" ? (
              <div
                className={classNames(
                  "absolute bottom-0 left-2/3 flex h-[16px] w-auto min-w-[16px] items-center justify-center rounded-[30px] px-1 text-xs font-bold text-neutral-100",
                  {
                    "bg-alert text-invert-primary ": isWarning,
                    "bg-neutral-600 text-neutral-100 hover:bg-neutral-900 dark:bg-invert-secondary dark:hover:bg-neutral-800":
                      !isWarning,
                  },
                )}
              >
                {numberIndicator}
              </div>
            ) : null}
            <button
              onClick={onButtonClick}
              className={classNames(
                "p-2.5 focus-visible:outline-2 focus-visible:outline-offset-[12px] focus-visible:outline-invert-primary disabled:opacity-30",
                { "rounded-full": rounded },
              )}
              disabled={other.disabled}
            >
              {icon}
            </button>
          </div>

          {Boolean(children) && renderChildren(isIdle ? false : open)}
        </>
      )}
    </HeadlessListbox>
  ) : (
    <button
      {...other}
      onClick={onButtonClick}
      className={classNames(
        "relative flex items-center justify-center rounded-full p-2.5 focus-visible:outline-invert-primary",
        "hover:bg-neutral-100 dark:hover:bg-neutral-800",
        {
          "cursor-not-allowed opacity-30 hover:bg-none": other.disabled,
          "rounded-full": rounded,
        },
        other.className,
      )}
    >
      <div className="toolbox-action-icon-wrapper relative h-6 w-6">
        {(numberIndicator && typeof numberIndicator === "string") ||
        (numberIndicator &&
          typeof numberIndicator === "number" &&
          numberIndicator > 0) ? (
          <div
            className={classNames(
              "absolute -bottom-[14px] -right-3 flex h-[16px] w-auto min-w-[16px] items-center justify-center rounded-[30px] px-1 text-xs font-bold text-neutral-100",
              {
                "bg-alert text-invert-primary": isWarning,
                "bg-neutral-600 text-neutral-100 hover:bg-neutral-900 dark:bg-invert-secondary dark:hover:bg-neutral-800":
                  !isWarning,
              },
            )}
          >
            {numberIndicator}
          </div>
        ) : null}

        {icon}
      </div>
    </button>
  );
};
