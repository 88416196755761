"use client";
import { Dialog, Transition } from "@headlessui/react";
import classnames from "classnames";
import { Fragment } from "react";
import { ModalContent, ModalContentProps } from "./ModalContent";
import { ModalFooter, ModalFooterProps } from "./ModalFooter";
import { ModalHeader, ModalHeaderProps } from "./ModalHeader";

export type ModalProps = {
  children?: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  className?: string;
  panelClassName?: string;
  element?: React.ElementType;
};

export type ModalStatic = {
  Header: React.FC<ModalHeaderProps>;
  Content: React.FC<ModalContentProps>;
  Footer: React.FC<ModalFooterProps>;
};

export const Modal: React.FC<ModalProps> & ModalStatic = ({
  children,
  onClose,
  isOpen,
  panelClassName,
  className,
  element = "section",
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as={element}
        className={classnames("relative z-50", className)}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-900 bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="grid min-h-full grid-cols-12 items-center justify-center gap-x-5 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={classnames(
                  " w-full transform overflow-hidden rounded-lg bg-neutral-50 p-7 text-left align-middle shadow-xl transition-all",
                  className,
                  panelClassName,
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;
