"use client";

import classnames from "classnames";
import React from "react";

export type IndicatorTypes = {
  maxSlideSteps: number;
  currentSlideStep: number;
  classNames?: string;
  onDark?: boolean;
};
const Indicator: React.FC<IndicatorTypes> = ({
  maxSlideSteps,
  currentSlideStep,
  classNames,
  onDark = false,
}) => {
  return (
    <div
      className={classnames(
        "relative mt-12 h-1.5 w-[calc(100%-28px)] rounded min-[1440px]:w-full",
        classNames,
        onDark ? "bg-primary/30" : "bg-invert-primary/30",
      )}
    >
      <div
        className={classnames(
          "absolute top-0 h-full rounded-full  transition-transform duration-500 ease-in-out",
          onDark ? "bg-primary" : "bg-invert-primary",
        )}
        style={{
          width: `${100 / maxSlideSteps}%`,
          transform: `translateX(${currentSlideStep * 100}%)`,
        }}
      />
    </div>
  );
};

export default Indicator;
