"use client";

import React from "react";
import classnames from "classnames";

export type TabPanelProps = {
  children?: React.ReactNode;
  className?: string;
};

export const TabPanel: React.FC<TabPanelProps> = ({ children, className }) => {
  return <div className={classnames("w-full", className)}>{children}</div>;
};
