import classnames from "classnames";

export type PatchbayDarkProps = {
  size: "small" | "medium" | "large";
  type: "primary" | "secondary" | "tertiary";
};

const PatchbayDark = ({ size = "large" }) => (
  <svg viewBox="0 0 286 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5362 6.98646C15.2756 6.26744 14.8647 5.75655 14.3034 5.45381C13.7622 5.15106 12.9805 4.99969 11.9583 4.99969C10.936 4.99969 10.1443 5.11322 9.58303 5.34028C9.04184 5.54842 8.77125 5.86062 8.77125 6.2769C8.77125 6.57964 8.89151 6.8067 9.13204 6.95807C9.39262 7.10945 9.86365 7.2419 10.5452 7.35543L15.4159 8.12175C18.3223 8.57587 20.3969 9.30435 21.6396 10.3072C22.9024 11.2911 23.5338 12.7008 23.5338 14.5362C23.5338 19.0395 19.7354 21.2912 12.1387 21.2912C4.92277 21.2912 1.08431 18.8408 0.623291 13.9401H8.23005C8.55076 15.5106 9.86365 16.2959 12.1687 16.2959C14.6342 16.2959 15.8669 15.7945 15.8669 14.7916C15.8669 14.4699 15.6965 14.224 15.3558 14.0537C15.035 13.8644 14.5139 13.7131 13.7923 13.5995L9.91376 13.0319C6.94723 12.5967 4.75239 11.8493 3.32925 10.7897C1.90612 9.71116 1.19455 8.27312 1.19455 6.47557C1.19455 4.45097 2.16669 2.87101 4.11098 1.73572C6.0753 0.581503 8.79129 0.00439453 12.2589 0.00439453C15.486 0.00439453 17.9916 0.54366 19.7755 1.62219C21.5795 2.6818 22.6218 4.27121 22.9024 6.39043L15.5362 6.98646Z"
      fill="white"
    />
    <path
      d="M40.3608 0.515277H48.1179V11.6412C48.1179 14.9335 47.1758 17.3649 45.2916 18.9354C43.4275 20.5059 40.5412 21.2912 36.6326 21.2912C32.7239 21.2912 29.8276 20.5059 27.9434 18.9354C26.0793 17.3649 25.1473 14.9335 25.1473 11.6412V0.515277H32.9043V12.2372C32.9043 13.3536 33.195 14.1672 33.7763 14.6781C34.3575 15.189 35.3096 15.4444 36.6326 15.4444C37.9555 15.4444 38.9076 15.189 39.4889 14.6781C40.0701 14.1672 40.3608 13.3536 40.3608 12.2372V0.515277Z"
      fill="white"
    />
    <path
      d="M51.2565 20.7803V0.515277H63.1026C69.3363 0.515277 72.4532 2.26552 72.4532 5.76601C72.4532 7.67709 71.3307 9.09621 69.0858 10.0234C70.4889 10.4586 71.5312 11.0735 72.2127 11.8682C72.9142 12.644 73.265 13.6279 73.265 14.82C73.265 16.7689 72.4432 18.2543 70.7996 19.276C69.176 20.2789 66.8008 20.7803 63.6739 20.7803H51.2565ZM62.8019 8.2069C64.3854 8.2069 65.1772 7.70548 65.1772 6.70263C65.1772 6.17283 64.9868 5.80386 64.6059 5.59572C64.2251 5.36866 63.5937 5.25513 62.7118 5.25513H58.292V8.2069H62.8019ZM62.9823 16.0404C64.0447 16.0404 64.7963 15.8985 65.2373 15.6147C65.6983 15.3309 65.9288 14.8768 65.9288 14.2523C65.9288 13.6279 65.6883 13.1833 65.2073 12.9184C64.7462 12.6345 63.9846 12.4926 62.9222 12.4926H58.292V16.0404H62.9823Z"
      fill="white"
    />
    <path
      d="M96.8238 20.7803V10.9032L92.4943 20.7803H86.932L82.6025 10.9032V20.7803H75.6572V0.515277H84.2862L89.8484 12.8048L95.4107 0.515277H103.919V20.7803H96.8238Z"
      fill="white"
    />
    <path d="M107.281 20.7803V0.515277H115.038V20.7803H107.281Z" fill="white" />
    <path
      d="M134.195 20.7803L129.565 14.8484L124.934 20.7803H116.365L124.874 10.2504L116.997 0.515277H126.047L129.715 5.56734L133.623 0.515277H142.192L134.465 10.0518L143.245 20.7803H134.195Z"
      fill="white"
    />
    <path
      d="M1.53041 69.4504V28.9632H20.6966C25.9134 28.9632 29.845 30.0217 32.4912 32.1387C35.1374 34.2557 36.4605 37.3933 36.4605 41.5517C36.4605 45.8612 35.0996 49.0745 32.3778 51.1915C29.6559 53.2706 25.5354 54.3102 20.0161 54.3102H7.14418V69.4504H1.53041ZM20.1863 49.3769C23.7397 49.3769 26.3671 48.7343 28.0682 47.449C29.7693 46.1259 30.6199 44.1223 30.6199 41.4383C30.6199 38.8298 29.7882 36.9208 28.1249 35.7111C26.4616 34.5014 23.8532 33.8965 20.2997 33.8965H7.14418V49.3769H20.1863Z"
      fill="white"
    />
    <path
      d="M63.3638 69.4504V64.8573C61.587 66.7097 59.6213 68.0706 57.4665 68.94C55.3495 69.7717 52.8923 70.1875 50.0949 70.1875C46.2768 70.1875 43.347 69.4126 41.3057 67.8626C39.2643 66.3127 38.2436 64.1012 38.2436 61.2282C38.2436 58.0905 39.5856 55.7656 42.2696 54.2535C44.9915 52.7036 49.2443 51.853 55.0282 51.7018L63.2504 51.475V50.4543C63.2504 48.0349 62.5321 46.2582 61.0956 45.1241C59.6969 43.9522 57.5043 43.3662 54.5178 43.3662C51.7204 43.3662 49.5278 43.7064 47.9401 44.3869C46.3902 45.0674 45.3506 46.1448 44.8213 47.6191L39.9447 46.9953C41.1166 41.8163 46.0499 39.2268 54.7447 39.2268C59.3566 39.2268 62.7778 40.153 65.0082 42.0053C67.2764 43.8577 68.4105 46.6929 68.4105 50.511V69.4504H63.3638ZM63.2504 55.2742L55.0849 55.501C51.1534 55.6144 48.2803 56.087 46.4658 56.9186C44.6512 57.7503 43.744 59.0356 43.744 60.7746C43.744 62.4379 44.4055 63.7043 45.7286 64.5738C47.0517 65.4432 48.9608 65.878 51.4558 65.878C53.2325 65.878 54.9148 65.6134 56.5025 65.0841C58.128 64.5171 59.5268 63.7421 60.6987 62.7592C62.3998 61.3605 63.2504 59.6405 63.2504 57.5991V55.2742Z"
      fill="white"
    />
    <path
      d="M93.9538 68.94C91.6856 69.6961 89.2662 70.0741 86.6955 70.0741C83.4823 70.0741 81.1196 69.2992 79.6075 67.7492C78.0953 66.1993 77.3393 63.7421 77.3393 60.3776V44.3302H71.6121V40.1341H77.3393V32.1387H82.6695V40.1341H93.1032V44.3302H82.6695V60.8313C82.6695 64.0823 84.3139 65.7079 87.6028 65.7079C89.4174 65.7079 91.2508 65.4054 93.1032 64.8006L93.9538 68.94Z"
      fill="white"
    />
    <path
      d="M126.394 59.4704C125.789 62.986 124.201 65.689 121.631 67.5791C119.06 69.4315 115.639 70.3577 111.367 70.3577C106.188 70.3577 102.124 68.9967 99.1755 66.2749C96.2646 63.5153 94.8092 59.7161 94.8092 54.8773C94.8092 51.7774 95.4707 49.0556 96.7939 46.7118C98.1548 44.368 100.083 42.5345 102.578 41.2114C105.111 39.8883 108.097 39.2268 111.537 39.2268C115.431 39.2268 118.587 40.0584 121.007 41.7218C123.464 43.3851 125.071 45.8045 125.827 48.98L120.553 49.8306C119.381 45.6722 116.319 43.593 111.367 43.593C107.927 43.593 105.243 44.5759 103.315 46.5417C101.387 48.5074 100.423 51.2482 100.423 54.7639C100.423 58.393 101.368 61.1715 103.258 63.0995C105.148 65.0274 107.889 65.9914 111.48 65.9914C116.962 65.9914 120.175 63.8177 121.12 59.4704H126.394Z"
      fill="white"
    />
    <path
      d="M156.799 69.4504V51.5317C156.799 48.8099 156.137 46.8441 154.814 45.6344C153.529 44.3869 151.431 43.7632 148.52 43.7632C146.97 43.7632 145.42 44.0656 143.87 44.6704C142.358 45.2375 141.054 46.0502 139.958 47.1087C139.013 47.9782 138.294 48.9611 137.803 50.0574C137.311 51.1159 137.066 52.2311 137.066 53.403V69.4504H131.735V28.9632H137.066V45.521C140.317 41.3248 144.589 39.2268 149.881 39.2268C153.926 39.2268 156.969 40.1908 159.01 42.1187C161.09 44.0089 162.129 46.8252 162.129 50.5677V69.4504H156.799Z"
      fill="white"
    />
    <path
      d="M169.234 69.4504V28.9632H174.564V45.4076C175.849 43.4796 177.645 41.9675 179.951 40.8712C182.257 39.7749 184.771 39.2268 187.493 39.2268C190.403 39.2268 192.974 39.8505 195.204 41.098C197.473 42.3077 199.23 44.0845 200.478 46.4283C201.763 48.7343 202.406 51.4561 202.406 54.5937C202.406 57.618 201.782 60.3209 200.535 62.7025C199.287 65.0841 197.492 66.9554 195.148 68.3163C192.804 69.6772 190.063 70.3577 186.926 70.3577C181.444 70.3577 177.305 68.4486 174.507 64.6305V69.4504H169.234ZM174.507 55.6144C174.507 58.4875 175.49 60.9447 177.456 62.986C178.401 63.9689 179.554 64.725 180.915 65.2542C182.314 65.7457 183.826 65.9914 185.451 65.9914C189.156 65.9914 191.972 65.0274 193.9 63.0995C195.828 61.1715 196.792 58.3552 196.792 54.6505C196.792 51.1726 195.828 48.4696 193.9 46.5417C191.972 44.5759 189.288 43.593 185.848 43.593C184.223 43.593 182.673 43.8955 181.198 44.5003C179.724 45.0674 178.458 45.8801 177.399 46.9386C176.492 47.8459 175.774 48.9044 175.244 50.1141C174.753 51.3238 174.507 52.5524 174.507 53.7999V55.6144Z"
      fill="white"
    />
    <path
      d="M231.252 69.4504V64.8573C229.475 66.7097 227.509 68.0706 225.354 68.94C223.238 69.7717 220.78 70.1875 217.983 70.1875C214.165 70.1875 211.235 69.4126 209.194 67.8626C207.152 66.3127 206.132 64.1012 206.132 61.2282C206.132 58.0905 207.474 55.7656 210.158 54.2535C212.879 52.7036 217.132 51.853 222.916 51.7018L231.138 51.475V50.4543C231.138 48.0349 230.42 46.2582 228.984 45.1241C227.585 43.9522 225.392 43.3662 222.406 43.3662C219.608 43.3662 217.416 43.7064 215.828 44.3869C214.278 45.0674 213.239 46.1448 212.709 47.6191L207.833 46.9953C209.005 41.8163 213.938 39.2268 222.633 39.2268C227.245 39.2268 230.666 40.153 232.896 42.0053C235.164 43.8577 236.298 46.6929 236.298 50.511V69.4504H231.252ZM231.138 55.2742L222.973 55.501C219.041 55.6144 216.168 56.087 214.354 56.9186C212.539 57.7503 211.632 59.0356 211.632 60.7746C211.632 62.4379 212.294 63.7043 213.617 64.5738C214.94 65.4432 216.849 65.878 219.344 65.878C221.121 65.878 222.803 65.6134 224.39 65.0841C226.016 64.5171 227.415 63.7421 228.587 62.7592C230.288 61.3605 231.138 59.6405 231.138 57.5991V55.2742Z"
      fill="white"
    />
    <path
      d="M253.229 68.7132L238.486 40.1341H244.724L256.121 63.1562L266.498 40.1341H272.452L256.915 72.7393C255.441 75.8391 253.891 77.9939 252.265 79.2036C250.678 80.4511 248.617 81.0748 246.084 81.0748C245.064 81.0748 244.157 80.9803 243.363 80.7913C242.569 80.6401 241.699 80.3755 240.754 79.9975L241.718 75.9147C242.966 76.3684 244.213 76.5952 245.461 76.5952C246.935 76.5952 248.164 76.2171 249.147 75.4611C250.167 74.7428 251.037 73.5898 251.755 72.0021L253.229 68.7132Z"
      fill="white"
    />
    <path
      d="M274.555 29.919H272.452V28.9632H277.741V29.919H275.638V35.3348H274.555V29.919ZM278.664 28.9632H280.003L282.057 34.0924L284.144 28.9632H285.466V35.3348H284.494V30.5402L282.503 35.3348H281.595L279.636 30.5561V35.3348H278.664V28.9632Z"
      fill="white"
    />
  </svg>
);

export { PatchbayDark };
