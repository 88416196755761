import { BaseSvgProps } from "./types";
const SvgAlertWarningTriangle = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M12 8.793v4.804m.8 4.804a.8.8 0 1 1-1.6 0 .8.8 0 0 1 1.6 0Zm.625-15.934a1.602 1.602 0 0 0-2.85 0L1.768 20.082a1.601 1.601 0 0 0 1.425 2.322h17.614a1.6 1.6 0 0 0 1.425-2.322L13.425 2.467Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgAlertWarningTriangle;
