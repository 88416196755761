"use client";
import classnames from "classnames";
import React, {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ChevronArrowDown } from "../Icons";
import { Input } from "../Input";
import { Label } from "../Typography/Label";
import { Button } from "../..";

export type TimePickerProps = {
  className?: string;
  label?: string;
  selectedTime: string;
  setSelectedTime: (time: string) => void;
  disabled?: boolean;
};

const inputClassNames = `
w-full
cursor-pointer
rounded
!border-secondary
!bg-secondary
py-3
pl-4
pr-8
leading-none
text-neutral-900
focus-within:border-tertiary
focus-visible:!bg-neutral-50
text-sm
hover:!bg-secondary-alt
focus-visible:none
!border
focus-visible:!border-tertiary`;

const get12HoursFormat = (date: Date) => {
  let hour = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const modifier = hour >= 12 ? "pm" : "am";
  hour = hour % 12 || 12;
  return `${hour}:${minutes}${modifier}`;
};

const timeItems = Array(24)
  .fill(null)
  .map((_, i) => {
    const hour = i % 12 || 12;
    const modifier = i < 12 ? "am" : "pm";
    return [
      `${hour}:00${modifier}`,
      `${hour}:15${modifier}`,
      `${hour}:30${modifier}`,
      ,
      `${hour}:45${modifier}`,
    ];
  })
  .flat();

const timeRegex = /^(1[0-2]|0?[1-9]):([0-5][0-9]|[0-9])(am|pm|AM|PM)$/;

const transformTime = (timeString: string) => {
  // Split the time string into its components
  let [hours, minutes, amPm] = timeString.match(timeRegex)?.slice(1) || [
    "12",
    "00",
    "am",
  ];

  // Pad the minutes with a leading zero if necessary
  if (minutes?.length === 1) {
    minutes = "0" + minutes;
  }

  // Omit leading zero
  if (hours && hours[0] === "0") {
    hours = hours.substring(1);
  }
  // Combine the transformed components and the AM/PM suffix
  const transformedTime = hours + ":" + minutes + amPm;
  return transformedTime;
};

export const TimePicker: React.FC<TimePickerProps> = ({
  className,
  label,
  selectedTime,
  setSelectedTime,
  disabled = false,
}) => {
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [scrollTopValue, setScrollTopValue] = useState(0);
  const [error, setError] = useState(false);
  const [lastValidTimeValue, setLastValidTimeValue] = useState(selectedTime);

  const scrollHostElRef = useRef<HTMLUListElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const toggleTimePicker = () => {
    if (disabled) return;
    setShowTimePicker((prevState) => !prevState);
  };

  useEffect(() => {
    if (showTimePicker && scrollTopValue < 1) {
      const modifier = selectedTime.slice(selectedTime.length - 2) || "am";
      const hours = selectedTime.split(":")[0] || "12";
      const timeListItems = scrollHostElRef.current?.querySelectorAll(
        "li",
      ) as unknown as Array<HTMLElement>;

      for (const timeListItem of timeListItems) {
        const timeListItemModifier =
          timeListItem?.textContent?.slice(selectedTime.length - 2) || "am";
        const timeListItemHours =
          timeListItem?.textContent?.split(":")[0] || "12";

        if (timeListItemModifier === modifier && timeListItemHours === hours) {
          setScrollTopValue(timeListItem.offsetTop);
          //Exit the loop as soon as we find first matching element
          break;
        }
      }
    }
  }, [showTimePicker, scrollTopValue]);

  const handleSelectTime = useCallback(
    (e: BaseSyntheticEvent) => {
      const { textContent } = e.target;
      setSelectedTime(textContent);
      setLastValidTimeValue(textContent);
      setShowTimePicker(false);
      if (scrollHostElRef?.current) {
        setScrollTopValue(scrollHostElRef?.current.scrollTop);
      }
    },
    [scrollHostElRef, setSelectedTime],
  );

  const handleOutsideClick = useCallback(
    (e: Event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target as Node)
      ) {
        // Outside click
        setShowTimePicker(false);
      }
    },
    [wrapperRef],
  );

  const handleOnChange = useCallback(
    (e: BaseSyntheticEvent) => {
      if (!timeRegex.test(e.target.value)) {
        setError(true);
      } else {
        setError(false);
        setLastValidTimeValue(e.target.value);
      }
      setSelectedTime(e.target.value);
    },
    [setSelectedTime],
  );

  const handleBlur = useCallback(() => {
    if (error) {
      const transformedLastValidValue = transformTime(lastValidTimeValue);
      setSelectedTime(transformedLastValidValue);
      setError(false);
    } else {
      const transformedSelectedTime = transformTime(selectedTime);
      setSelectedTime(transformedSelectedTime);
    }
  }, [error, lastValidTimeValue, selectedTime, setSelectedTime]);

  useEffect(() => {
    showTimePicker &&
      scrollHostElRef.current &&
      scrollHostElRef.current.scrollTo(0, scrollTopValue);
  }, [scrollTopValue, showTimePicker]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!selectedTime) {
      setSelectedTime(get12HoursFormat(new Date()));
      setLastValidTimeValue(get12HoursFormat(new Date()));
    }
  }, []);

  return (
    <>
      <div
        ref={wrapperRef}
        className={classnames(
          "relative h-fit w-fit max-w-full rounded",
          className,
        )}
      >
        {label && (
          <Label className="mb-1 block text-neutral-900">{label}</Label>
        )}
        <div className="relative">
          <Input
            type="text"
            className={classnames(
              inputClassNames,
              error && "!border-red-600 !outline-red-600",
              disabled && "cursor-auto !text-neutral-300 hover:!shadow-none",
              showTimePicker &&
                "!border !border-tertiary !bg-white !ring-2 !ring-invert-primary !ring-offset-4",
            )}
            value={selectedTime}
            onChange={handleOnChange}
            placeholder={"07:00pm"}
            onBlur={handleBlur}
            onClick={toggleTimePicker}
            disabled={disabled}
          />
          <Button
            onClick={toggleTimePicker}
            className={classnames(
              "absolute right-4 top-0 flex h-full cursor-pointer items-center justify-end bg-transparent !px-0 [&_span]:pb-0",
              disabled && "cursor-auto",
            )}
            color="transparent"
            variant="tertiary"
            type="button"
          >
            <ChevronArrowDown
              stroke={disabled ? "#D6D6D6" : "black"}
              strokeWidth={4}
              height={12}
              width={12}
              className={classnames(
                "transition-transform duration-200 ease-in-out",
                showTimePicker && "rotate-180",
              )}
            />
          </Button>
        </div>
        {showTimePicker && (
          <div className="absolute left-0 top-full z-10 mt-2.5 w-[180px] rounded bg-secondary shadow-lg">
            <div className="mb-2 text-center">
              <ul
                onClick={handleSelectTime}
                className="time-picker-scrollbar h-[180px] max-h-[180px] snap-y snap-mandatory overflow-y-scroll"
                ref={scrollHostElRef}
              >
                {timeItems.map((timeItem) => {
                  const timeClassNames =
                    "text-left leading-5 w-full focus:outline-none py-2 px-4 cursor-pointer text-invert-primary text-sm focus-visible:!border-2 focus-visible:!border-invert-primary hover:bg-secondary-alt snap-start ";
                  return (
                    <li
                      key={timeItem}
                      tabIndex={0}
                      className={classnames(
                        timeClassNames,
                        selectedTime === timeItem &&
                          "bg-invert-primary text-primary hover:bg-neutral-800 hover:text-invert-primary focus-visible:!border-primary",
                      )}
                    >
                      {timeItem}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
