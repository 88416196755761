import classnames from "classnames";
import { LinkClasses } from "../Link";
import { Typography } from "../Typography";

export type HeaderLinkProps = {
  href: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  loading?: boolean;
  className?: string;
  LinkComponent: React.ComponentType<any>;
};

const baseLinkClassName = `
flex
h-[40px]
w-[40px]
items-center
justify-center
!bg-neutral-50
!p-0
lg:h-[40px]
lg:w-auto
lg:!px-4
lg:!py-2
hover:!bg-neutral-100
hover:shadow-lg
gap-2.5
`;

export const HeaderLink: React.FC<HeaderLinkProps> = ({
  href,
  children,
  icon,
  loading = false,
  className,
  LinkComponent,
}) => {
  return (
    <LinkComponent
      href={href}
      to={href}
      className={classnames(
        LinkClasses.baseClassName,
        baseLinkClassName,
        className,
      )}
    >
      {icon}
      {!loading ? (
        <Typography.Body
          element="span"
          className="hidden pt-[2px] !font-semibold !leading-none lg:block"
        >
          {children}
        </Typography.Body>
      ) : (
        <Typography.Body
          element="span"
          className="hidden animate-pulse rounded bg-neutral-300 lg:block lg:h-4 lg:w-[61px]"
        />
      )}
    </LinkComponent>
  );
};
