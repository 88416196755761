/* eslint-disable @typescript-eslint/no-explicit-any */
import { Combobox as HeadlessComobox, Transition } from "@headlessui/react";
import classnames from "classnames";
import {
  BaseSyntheticEvent,
  ForwardedRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChevronArrowDown } from "../Icons";
import { Input } from "../Input";
import { ErrorMessage } from "../Typography/ErrorMessage";
import { ComboboxOption } from "./index";
import { ComboboxRef } from "./types";
import { Typography } from "../Typography";

export type ComboboxContentTypes = {
  customRef: ForwardedRef<ComboboxRef>;
  onDark?: boolean;
  label: string;
  options: Array<ComboboxOption>;
  noOptionsMessage?: string;
  error?: string;
  other: Omit<React.HTMLProps<HTMLInputElement>, "as">;
  isMultiple?: boolean;
  invalid?: boolean;
  selectedValue?: ComboboxOption;
  selectedValues?: ComboboxOption[];
  clearOnClick?: boolean;
};

const ComboboxContent: React.FC<ComboboxContentTypes> = ({
  customRef,
  onDark,
  label,
  options,
  noOptionsMessage,
  error,
  other,
  isMultiple = false,
  invalid,
  selectedValue,
  selectedValues,
  clearOnClick,
}) => {
  const [showError, setShowError] = useState(false);
  const [query, setQuery] = useState((other.defaultValue as string) || "");
  const [isActive, setIsActive] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const getValue = useCallback(() => inputRef?.current?.value, []);
  const getValidity = useCallback(() => {
    if (inputRef?.current?.validity.valid) {
      if (other.required || other["aria-required"]) {
        if (isMultiple) {
          if (!selectedValues || selectedValues.length === 0) {
            inputRef.current.setCustomValidity("Missing value");
          }
          setShowError(!selectedValues || selectedValues.length === 0);
        } else {
          if (!selectedValue) {
            inputRef.current.setCustomValidity("Missing value");
          }
          setShowError(!selectedValue);
        }
      } else {
        setShowError(false);
      }
    } else {
      setShowError(true);
    }
    return inputRef?.current?.validity;
  }, [selectedValues, selectedValue]);

  const setValue = useCallback((value: string) => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = value;
      setQuery(value);
    }
  }, []);

  const clearValue = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
      setQuery("");
      setShowError(false);
    }
  }, []);

  useImperativeHandle(
    customRef,
    () => ({ getValue, setValue, clearValue, getValidity }),
    [getValue, setValue, getValidity, clearValue],
  );

  const resetTouch = useCallback(
    (event: React.FocusEvent<HTMLInputElement, Element>) => {
      setShowError(false);
      setIsActive(false);
      if (other.onBlur) {
        other.onBlur(event);
      }
    },
    [other.onBlur],
  );
  const filteredOptions = useMemo(
    () =>
      query === ""
        ? options
        : options.filter((option: ComboboxOption) => {
            return option.name.toLowerCase().includes(query.toLowerCase());
          }),
    [query, options],
  );

  const singleSelectDisplayValue = useMemo(
    () =>
      (option: any): string => {
        if (option) {
          inputRef.current?.setCustomValidity("");
        }

        return option ? (option as ComboboxOption).name : "";
      },
    [],
  );

  const multiSelectDisplayValue = useMemo(
    () =>
      (options: any[]): string => {
        if (options.length > 0) {
          inputRef.current?.setCustomValidity("");
        }
        return options.map((option) => option.name).join(", ");
      },
    [],
  );

  const handleInputClick = useCallback(
    (e: BaseSyntheticEvent) => {
      if (clearOnClick && e.currentTarget.name === "open-combobox-btn") {
        clearValue();
      }
      setIsActive(true);
    },
    [clearOnClick],
  );

  return (
    <>
      <HeadlessComobox.Label
        className={classnames(
          onDark && "text-neutral-50",
          "[&+div]:ui-not-open:bg-secondary [&+div]:ui-open:bg-primary col-start-1 col-end-3 text-invert-primary",
          "!text-sm",
        )}
      >
        <Typography.Body element="span" className="!text-sm">
          {label}
        </Typography.Body>
        <ErrorMessage
          className={classnames(
            `ml-2.5 hidden`,
            (showError || invalid) && error && "!inline-block",
          )}
        >
          {error}
        </ErrorMessage>
      </HeadlessComobox.Label>
      <div
        className={classnames(
          Input.classes,
          "align-center ui-open:bg-secondary relative !mt-1 grid grid-cols-[1fr_40px] border-0 text-neutral-900 !ring-offset-4",
          (showError || invalid) && "border-2 !border-negative",
          {
            "bg-secondary": !isActive,
            "bg-transparent": isActive,
          },
        )}
      >
        <HeadlessComobox.Input
          required={other.required}
          aria-required={other["aria-required"]}
          placeholder={other.placeholder}
          ref={inputRef}
          className={
            "peer min-w-[150px] truncate bg-transparent leading-[100%] placeholder:text-invert-primary focus-visible:outline-none"
          }
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          displayValue={
            isMultiple ? multiSelectDisplayValue : singleSelectDisplayValue
          }
          onBlur={resetTouch}
          onClick={handleInputClick}
        />
        <HeadlessComobox.Button
          onClick={handleInputClick}
          className="self-center justify-self-end"
          name="open-combobox-btn"
        >
          <ChevronArrowDown
            stroke="black"
            width={12}
            height={12}
            strokeWidth={4}
            className={classnames(
              "ui-open:rotate-180 ui-open:transform ui-not-open:rotate-0 transition-transform duration-200 ease-in-out",
            )}
          />
        </HeadlessComobox.Button>
        <Transition
          className={`absolute top-full z-20 my-3 w-full`}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <HeadlessComobox.Options
            className={`custom-scrollbar max-h-64 overflow-y-auto overscroll-contain	rounded border border-neutral-500 bg-neutral-50`}
          >
            {filteredOptions.length === 0 && query !== "" && (
              <li
                className="cursor-pointer px-4
              py-3"
              >
                {noOptionsMessage}
              </li>
            )}
            {filteredOptions.map((option: ComboboxOption) => (
              /* Use the `active` state to conditionally style the active option. */
              /* Use the `selected` state to conditionally style the selected option. */
              <HeadlessComobox.Option
                key={option.id}
                value={option}
                as={Fragment}
              >
                {({ active, selected }) => (
                  <li
                    className={`
                    cursor-pointer rounded
                    px-4
                    py-3
                    hover:bg-neutral-100
                    ${
                      active || selected
                        ? " text-green-700"
                        : "text-neutral-900"
                    }`}
                  >
                    {option.name}
                  </li>
                )}
              </HeadlessComobox.Option>
            ))}
          </HeadlessComobox.Options>
        </Transition>
      </div>
    </>
  );
};

export default ComboboxContent;
