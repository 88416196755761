"use client";
import classNames from "classnames";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Input, InputRef } from "../Input";
import { Textarea, TextareaRef } from "../Textarea";
import { ErrorMessage } from "../Typography/ErrorMessage";
import { Label } from "../Typography/Label";

export type FieldProps = {
  className?: string;
  label: string;
  error?: string;
  isTextarea?: boolean;
  isSuccess?: boolean;
  onDark?: boolean;
  superScript?: string;
  invalid?: boolean;
  labelInfo?: React.ReactNode;
} & React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>;

export type FieldRef = {
  getValue: () => string | undefined;
  getValidity: () => ValidityState | undefined;
  setValue: (value: string) => void;
  clearValue: () => void;
};

const Field = forwardRef<FieldRef, FieldProps>(
  (
    {
      className,
      label,
      error,
      value,
      isTextarea,
      onDark,
      superScript,
      invalid = false,
      labelInfo,
      ...other
    },
    customRef,
  ) => {
    const inputRef = useRef<InputRef | TextareaRef>();
    const [showError, setShowError] = useState(false);

    const getValue = useCallback(() => inputRef?.current?.getValue(), []);
    const getValidity = useCallback(() => {
      setShowError(true);
      return inputRef?.current?.getValidity();
    }, []);

    const setValue = useCallback((value: string) => {
      if (inputRef && inputRef.current) {
        inputRef.current.setValue(value);
      }
    }, []);

    const clearValue = useCallback(() => {
      // reset state
      setShowError(false);
      if (inputRef && inputRef.current) {
        inputRef.current.clearValue();
      }
    }, []);

    useImperativeHandle(
      customRef,
      () => ({ getValue, setValue, clearValue, getValidity }),
      [getValue, setValue, clearValue, getValidity],
    );

    const resetTouch = useCallback(
      (
        event: React.FocusEvent<
          HTMLInputElement | HTMLTextAreaElement,
          Element
        >,
      ) => {
        setShowError(false);
        if (other.onBlur) {
          other.onBlur(event);
        }
      },
      [other.onBlur],
    );

    return (
      <Label
        onDark={onDark}
        className={classNames(className, "flex flex-col-reverse")}
      >
        {!isTextarea && (
          <Input
            defaultValue={value}
            className={classNames(
              `peer mb-5 mt-1`,
              showError && " invalid:border-2 invalid:border-negative",
              invalid && " border-2 border-negative",
            )}
            // @ts-ignore
            ref={inputRef}
            onBlur={resetTouch}
            {...other}
          />
        )}
        {isTextarea && (
          <Textarea
            className={classNames(
              `peer mb-5 mt-1 h-auto`,
              showError && " invalid:border-2 invalid:border-negative",
              invalid && " border-2 border-negative",
            )}
            // @ts-ignore
            ref={inputRef}
            onBlur={resetTouch}
            {...other}
          />
        )}
        <div
          className={classNames(
            "felx-row invisible flex items-center gap-[10px]",
            showError && "peer-invalid:visible",
            !label && "!gap-0",
          )}
        >
          <div className="!visible max-w-fit">
            {label}
            {superScript && <sup>{superScript}</sup>}
            {labelInfo && (
              <div className="!visible relative top-[3px] ml-2.5 inline-block">
                {labelInfo}
              </div>
            )}
          </div>

          <ErrorMessage
            className={classNames(
              `block`,
              showError && "peer-invalid:visible",
              invalid && "!visible font-normal",
            )}
          >
            {error}
          </ErrorMessage>
        </div>
      </Label>
    );
  },
);

export { Field };
