import { useState } from "react";

export const useCurrentSliderStep = (initStep: number, maxSteps: number) => {
  const [currentSlideStep, setCurrentSlideStep] = useState(initStep);

  const nextSlideStep = () => {
    if (currentSlideStep >= maxSteps) {
      return;
    }
    setCurrentSlideStep((prevState) => prevState + 1);
  };

  const prevSlideStep = () => {
    if (currentSlideStep === initStep) {
      return;
    }
    setCurrentSlideStep((prevState) => prevState - 1);
  };

  return { currentSlideStep, nextSlideStep, prevSlideStep };
};
