"use client";

import React from "react";
import { Button, Tier } from "@submix/component-library";
import classnames from "classnames";
import { usePricingIntervalSwitch } from "../IntervalSwitch/Provider";
import { ActivePlan } from "../types";

export type CtaTextOptions = {
  chooseThisPlan: string;
  buyPlan: string;
  switchYearly: string;
  switchMonthly: string;
  upgrade: string;
  downgrade: string;
  yourCurrentPlan: string;
};

export type CTAProps = {
  activePlan?: ActivePlan;
  tier: Tier;
  LinkComponent: React.ComponentType<any>;
  isCards?: boolean;
  ctaTextOptions: CtaTextOptions;
};

const isPlanUpgrade = (
  currentPlanPrice: number,
  currentPlanInterval: "yearly" | "monthly" | "weekly",
  nextPlanPrice: number,
  nextPlanInterval: "yearly" | "monthly" | "weekly",
) => {
  const currentPlanMonthlyPrice =
    currentPlanInterval === "monthly"
      ? currentPlanPrice
      : currentPlanPrice / 12;
  const nextPlanMonthlyPrice =
    nextPlanInterval === "monthly" ? nextPlanPrice : nextPlanPrice / 12;

  return nextPlanMonthlyPrice > currentPlanMonthlyPrice;
};

const CTA: React.FC<CTAProps> = ({
  tier,
  LinkComponent,
  isCards,
  activePlan,
  ctaTextOptions,
}) => {
  const { interval, isAnnually } = usePricingIntervalSwitch();
  const tierPrice =
    interval === "monthly"
      ? tier.price.monthly.value || 0
      : tier.price.annually.value || 0;

  if (!activePlan) {
    return (
      <div className="mt-auto">
        <LinkComponent
          href={isAnnually ? tier.href.annually : tier.href.monthly}
          className={classnames(
            Button.classes.baseClassName,
            Button.classes.classNames.primary,
            Button.classes.classNames.large,
            Button.classes.classNames.primary_black,
            "mt-6 block w-full text-center !text-base shadow-[0px_4px_15px_0px_#26262626]",
          )}
        >
          {isCards ? ctaTextOptions.chooseThisPlan : ctaTextOptions.buyPlan}
        </LinkComponent>
      </div>
    );
  }

  const isUpgrade = isPlanUpgrade(
    activePlan.price.value,
    activePlan.interval,
    tierPrice,
    interval,
  );
  const isSameInterval =
    (isAnnually && activePlan.interval === "yearly") ||
    (!isAnnually && activePlan.interval === "monthly");

  let link = "";
  let text = "";

  if (tier.isCurrentlyActive && !isSameInterval) {
    if (isAnnually) {
      text = ctaTextOptions.switchYearly;
      link = `${tier.href.downgradeYearly}&${
        tier.href.switchIntervalParams || ""
      }`;
    } else {
      text = ctaTextOptions.switchMonthly;
      link = `${tier.href.upgradeMonthly}?${
        tier.href.switchIntervalParams || ""
      }`;
    }
  } else if (isUpgrade) {
    text = ctaTextOptions.upgrade;
    if (isAnnually) {
      link = tier.href.upgradeYearly;
    } else {
      link = tier.href.upgradeMonthly;
    }
  } else if (!isUpgrade) {
    text = ctaTextOptions.downgrade;
    if (isAnnually) {
      link = tier.href.downgradeYearly;
    } else {
      link = tier.href.downgradeMonthly;
    }
  }

  return (
    <div className="mt-auto">
      {tier.isCurrentlyActive && isSameInterval && (
        <Button
          disabled
          color="black"
          variant="secondary"
          className="mt-7.5 w-full"
          size="large"
        >
          {ctaTextOptions.yourCurrentPlan}
        </Button>
      )}
      {!(tier.isCurrentlyActive && isSameInterval) && (
        <LinkComponent
          href={link}
          className={classnames(
            Button.classes.baseClassName,
            Button.classes.classNames.primary,
            Button.classes.classNames.large,
            Button.classes.classNames.primary_black,
            "mt-7.5 block w-full text-center !text-base shadow-[0px_4px_15px_0px_#26262626]  hover:!bg-brand hover:!text-neutral-900",
          )}
        >
          {text}
        </LinkComponent>
      )}
    </div>
  );
};

export default CTA;
