import classnames from "classnames";

export type LogoBaseDarkProps = {
  size: "small" | "medium" | "large";
  type: "primary" | "secondary" | "tertiary";
};

const LogoBaseDark = ({}) => (
  <svg viewBox="0 0 337 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.2637 17.8385C34.6482 16.0393 33.6776 14.761 32.3519 14.0034C31.0735 13.2459 29.227 12.8671 26.8124 12.8671C24.3977 12.8671 22.5275 13.1512 21.2018 13.7194C19.9235 14.2402 19.2843 15.0214 19.2843 16.063C19.2843 16.8205 19.5684 17.3887 20.1365 17.7675C20.752 18.1462 21.8647 18.4777 23.4745 18.7617L34.9796 20.6793C41.8448 21.8156 46.7452 23.6384 49.6806 26.1478C52.6635 28.6098 54.1549 32.1371 54.1549 36.7297C54.1549 47.9981 45.1828 53.6323 27.2385 53.6323C10.1938 53.6323 1.12699 47.501 0.0380249 35.2383H18.006C18.7635 39.168 21.8647 41.1329 27.3095 41.1329C33.1331 41.1329 36.0449 39.8782 36.0449 37.3688C36.0449 36.564 35.6425 35.9484 34.8376 35.5223C34.08 35.0489 32.849 34.6701 31.1446 34.386L21.983 32.9656C14.9758 31.8767 9.79137 30.0065 6.42978 27.3551C3.06819 24.6564 1.3874 21.058 1.3874 16.5601C1.3874 11.4941 3.68369 7.54066 8.27629 4.69988C12.9162 1.81175 19.3317 0.367683 27.5226 0.367683C35.1453 0.367683 41.0636 1.71706 45.2774 4.4158C49.5386 7.06719 52.0006 11.0443 52.6635 16.3471L35.2637 17.8385Z"
      fill="white"
    />
    <path
      d="M93.9019 1.64603H112.225V29.4857C112.225 37.7239 110 43.8079 105.549 47.7377C101.146 51.6674 94.3281 53.6323 85.0955 53.6323C75.863 53.6323 69.0214 51.6674 64.5709 47.7377C60.1677 43.8079 57.9661 37.7239 57.9661 29.4857V1.64603H76.2891V30.9771C76.2891 33.7705 76.9756 35.8064 78.3487 37.0848C79.7217 38.3631 81.9707 39.0023 85.0955 39.0023C88.2204 39.0023 90.4693 38.3631 91.8424 37.0848C93.2154 35.8064 93.9019 33.7705 93.9019 30.9771V1.64603Z"
      fill="white"
    />
    <path
      d="M119.639 52.354V1.64603H147.62C162.345 1.64603 169.707 6.02557 169.707 14.7846C169.707 19.5666 167.056 23.1176 161.753 25.4376C165.068 26.5265 167.53 28.0653 169.139 30.0538C170.796 31.995 171.625 34.457 171.625 37.4399C171.625 42.3165 169.684 46.0332 165.801 48.5899C161.966 51.0993 156.356 52.354 148.97 52.354H119.639ZM146.91 20.8923C150.651 20.8923 152.521 19.6376 152.521 17.1283C152.521 15.8026 152.071 14.8793 151.171 14.3585C150.272 13.7904 148.78 13.5063 146.697 13.5063H136.257V20.8923H146.91ZM147.336 40.4937C149.846 40.4937 151.621 40.1386 152.663 39.4284C153.752 38.7182 154.296 37.5819 154.296 36.0195C154.296 34.457 153.728 33.3444 152.592 32.6816C151.503 31.9714 149.704 31.6163 147.194 31.6163H136.257V40.4937H147.336Z"
      fill="white"
    />
    <path
      d="M227.273 52.354V27.6392L217.046 52.354H203.908L193.681 27.6392V52.354H177.275V1.64603H197.658L210.797 32.3975L223.935 1.64603H244.034V52.354H227.273Z"
      fill="white"
    />
    <path d="M251.974 52.354V1.64603H270.297V52.354H251.974Z" fill="white" />
    <path
      d="M315.547 52.354L304.61 37.5109L293.673 52.354H273.433L293.531 26.0057L274.924 1.64603H296.301L304.965 14.2875L314.198 1.64603H334.438L316.186 25.5086L336.924 52.354H315.547Z"
      fill="white"
    />
  </svg>
);

export { LogoBaseDark };
