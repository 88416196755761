"use client";

import { useEffect, useRef, useState } from "react";
import { Button } from "../../Button";
import { ArrowCurvedLeft, ArrowCurvedRight } from "../../Icons";
import Indicator from "../Indicator";
import { ITEM_MIN_WIDTH_IN_PX } from ".";

type DesktopSliderControlsProps = {
  prevSlideStep: () => void;
  nextSlideStep: () => void;
  currentSlideStep: number;
  itemsCount: number;
  onDark?: boolean;
};

const DesktopSliderControls = ({
  prevSlideStep,
  nextSlideStep,
  currentSlideStep,
  itemsCount,
  onDark = false,
}: DesktopSliderControlsProps) => {
  const [maxSlideSteps, setMaxSlideSteps] = useState(0);
  const controlsWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Calculate max slide steps so we don't end up on a step where
    // only one Carousel item is visible on a large screen.
    const handleSetMaxSlideSteps = (width: number) => {
      // If items can fit in wrapper without overflow
      // no need for steps
      if (itemsCount * ITEM_MIN_WIDTH_IN_PX < width) {
        setMaxSlideSteps(0);
        return;
      }
      // Remove one step on large screen to prevent
      // last slider item beeing alone on a large screens
      if (width > 2 * ITEM_MIN_WIDTH_IN_PX) {
        setMaxSlideSteps(itemsCount - 2);
        return;
      }
      setMaxSlideSteps(itemsCount - 1);
    };
    const handleCalcMaxSteps = () => {
      if (controlsWrapperRef.current) {
        const { current } = controlsWrapperRef;
        const boundingRect = current.getBoundingClientRect();
        const { width } = boundingRect;
        handleSetMaxSlideSteps(width);
      }
    };
    if (controlsWrapperRef.current) {
      handleCalcMaxSteps();
    }
    if (window) {
      window.addEventListener("resize", handleCalcMaxSteps);
    }

    return () => {
      if (window) {
        window.removeEventListener("resize", handleCalcMaxSteps);
      }
    };
  }, [itemsCount]);

  return (
    <div
      ref={controlsWrapperRef}
      className="col-span-10 col-start-2 hidden md:block"
    >
      {maxSlideSteps > 0 && itemsCount > 1 ? (
        <>
          {currentSlideStep > 0 && (
            <Button
              aria-label="left"
              className="absolute left-0 top-1/2 z-50 !h-fit -translate-y-1/2 "
              variant="primary"
              color={onDark ? "white" : "black"}
              leftIcon={
                <ArrowCurvedLeft width={16} height={16} strokeWidth={2} />
              }
              onClick={prevSlideStep}
              size="large"
            />
          )}
          {currentSlideStep < maxSlideSteps && (
            <Button
              aria-label="right"
              className="absolute right-0 top-1/2 z-50 !h-fit -translate-y-1/2"
              variant="primary"
              color={onDark ? "white" : "black"}
              leftIcon={
                <ArrowCurvedRight width={16} height={16} strokeWidth={2} />
              }
              onClick={nextSlideStep}
              size="large"
            />
          )}
        </>
      ) : null}
      <Indicator
        maxSlideSteps={maxSlideSteps + 1}
        currentSlideStep={currentSlideStep}
        onDark={onDark}
        classNames="!w-full"
      />
    </div>
  );
};

export default DesktopSliderControls;
