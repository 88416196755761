import { BaseSvgProps } from "./types";
const SvgProPlanSubscription = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
  fill = "#0FCD5B",
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M30 60c16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30Z"
      fill={fill}
    />
    <path
      d="m31.229 14.387 3.88 7.834a1.244 1.244 0 0 0 1 .732l8.567 1.27a1.318 1.318 0 0 1 .732 2.27l-6.174 6.125a1.294 1.294 0 0 0-.39 1.171l1.488 8.615a1.342 1.342 0 0 1-1.953 1.416l-7.711-4.076a1.44 1.44 0 0 0-1.27 0l-7.712 4.076a1.343 1.343 0 0 1-1.952-1.416l1.489-8.712a1.293 1.293 0 0 0-.39-1.172l-6.248-6.028a1.318 1.318 0 0 1 .805-2.27l8.566-1.269a1.244 1.244 0 0 0 1-.732l3.881-7.834a1.319 1.319 0 0 1 2.392 0Z"
      fill="#fff"
    />
  </svg>
);
export default SvgProPlanSubscription;
