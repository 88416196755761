"use client";
import classnames from "classnames";
import { Disclosure } from "@headlessui/react";
import { BaseTypography } from "../Typography/types";
import { AccordionButton, AccordionButtonProps } from "./AccordionButton";
import { AccordionPanel } from "./AccordionPanel";

export type AccordionProps = {
  defaultOpen?: boolean;
} & BaseTypography;

export type AccordionStatic = {
  Button: React.FunctionComponent<AccordionButtonProps & BaseTypography>;
  Panel: React.FunctionComponent<BaseTypography>;
};

const baseClassName = `rounded`;

const Accordion: React.FunctionComponent<AccordionProps> & AccordionStatic = ({
  children,
  defaultOpen = false,
  className,
}) => {
  return (
    <div className={classnames(baseClassName, className)}>
      <Disclosure defaultOpen={defaultOpen}>{children}</Disclosure>
    </div>
  );
};

Accordion.Button = AccordionButton;
Accordion.Panel = AccordionPanel;

export { Accordion };
