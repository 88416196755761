import { BaseSvgProps } from "./types";
const SvgRadioError = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <rect
      x={0.5}
      y={1.224}
      width={19}
      height={19}
      rx={9.5}
      fill="transparent"
      stroke="#D91B1B"
    />
  </svg>
);
export default SvgRadioError;
