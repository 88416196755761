import { BaseSvgProps } from "./types";
const SvgIntroPlanSubscription = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
  fill = "#0FCD5B",
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M30 60c16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30Z"
      fill={fill}
    />
    <path
      d="M30.646 30a7.2 7.2 0 1 0 0-14.4 7.2 7.2 0 0 0 0 14.4ZM44.337 44.4a14.378 14.378 0 0 0-27.382 0h27.382Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIntroPlanSubscription;
