import { BaseSvgProps } from "./types";
const SvgAlertWarningInfoCircle = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M12 6.398V11.2m10.404.8c0 5.746-4.658 10.404-10.404 10.404-5.746 0-10.404-4.658-10.404-10.404C1.596 6.254 6.254 1.596 12 1.596c5.746 0 10.404 4.658 10.404 10.404ZM12.8 16.002a.8.8 0 1 1-1.6 0 .8.8 0 0 1 1.6 0Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgAlertWarningInfoCircle;
