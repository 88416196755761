"use client";

import { useCurrentSliderStep } from "../../hooks/useCurrentSliderStep";
import { Button } from "../Button";
import { ArrowCurvedLeft, ArrowCurvedRight } from "../Icons";
import Indicator from "./Indicator";

type MobileSliderProps = {
  children: Array<React.ReactNode>;
  onDark?: boolean;
};

const MobileSlider = ({ children, onDark = false }: MobileSliderProps) => {
  const { currentSlideStep, nextSlideStep, prevSlideStep } =
    useCurrentSliderStep(0, children.length);

  return (
    <>
      {children.length > 1 ? (
        <div className="absolute -top-[53px] right-0 flex -translate-y-1/2 items-center gap-2.5 pr-7 sm:pr-0">
          <Button
            aria-label="left"
            className="!h-fit"
            variant="primary"
            color={onDark ? "white" : "black"}
            leftIcon={
              <ArrowCurvedLeft width={16} height={16} strokeWidth={2} />
            }
            onClick={prevSlideStep}
            disabled={currentSlideStep === 0}
          />
          <Button
            aria-label="right"
            className="!h-fit"
            variant="primary"
            color={onDark ? "white" : "black"}
            leftIcon={
              <ArrowCurvedRight width={16} height={16} strokeWidth={2} />
            }
            onClick={nextSlideStep}
            disabled={currentSlideStep >= children.length - 1}
          />
        </div>
      ) : null}
      <div
        className="grid gap-5 overflow-hidden transition-transform duration-500 ease-in-out"
        style={{
          width: `calc(${children.length}00% - ${children.length * 20}px)`,
          gridTemplateColumns: `repeat(${children.length * 6}, 1fr)`,
          transform: currentSlideStep
            ? `translateX(calc(-${
                currentSlideStep * (100 / children.length)
              }% - ${currentSlideStep * 4}px)`
            : "translateX(0)",
        }}
      >
        {children}
      </div>
      <Indicator
        maxSlideSteps={children.length}
        currentSlideStep={currentSlideStep}
        onDark={onDark}
      />
    </>
  );
};

export default MobileSlider;
