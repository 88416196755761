"use client";
import { Listbox as HeadlessListbox } from "@headlessui/react";
import { ValidationCheckmarkOk } from "@submix/component-library";
import classNames from "classnames";

export type MenuItemProps = {
  className?: string;
  value: {
    id: string;
    name: string;
  };
  children: React.ReactNode;
};

const MenuItem = ({ className, value, children }: MenuItemProps) => {
  return (
    <HeadlessListbox.Option
      value={value}
      className={({ active }) =>
        classNames(
          active ? " bg-neutral-300" : "",
          "relative cursor-pointer select-none  py-2 pl-9  pr-4 font-ui text-base text-neutral-900",
          className,
        )
      }
    >
      {({ selected }) => (
        <div className="flex items-center gap-3">
          {selected && (
            <span className="absolute inset-y-0 left-0 flex items-center pl-1.5">
              <ValidationCheckmarkOk
                width={16}
                height={16}
                stroke="black"
                className="h-4 w-4"
                aria-hidden="true"
              />
            </span>
          )}
          {children}
        </div>
      )}
    </HeadlessListbox.Option>
  );
};

export default MenuItem;
