import { BaseSvgProps } from "./types";
const SvgOrganizationEnterpriseEducationPlanSubscription = ({
  width,
  height,
}: BaseSvgProps) => (
  <svg
    width={width || "60"}
    height={height || "61"}
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 60.0939C46.5685 60.0939 60 46.6624 60 30.0938C60 13.5252 46.5685 0.09375 30 0.09375C13.4315 0.09375 0 13.5252 0 30.0938C0 46.6624 13.4315 60.0939 30 60.0939Z"
      fill="#8CE300"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7779 14.7285C24.8231 14.7285 23.9074 15.1078 23.2323 15.7829C22.5572 16.4581 22.1779 17.3737 22.1779 18.3285V20.8588H18.5779C17.7595 20.8588 16.9747 21.1839 16.396 21.7626C15.8173 22.3413 15.4922 23.1261 15.4922 23.9445V40.4017C15.4922 40.8069 15.572 41.2081 15.7271 41.5825C15.8821 41.9569 16.1094 42.297 16.396 42.5836C16.6825 42.8701 17.0227 43.0974 17.397 43.2525C17.7714 43.4076 18.1727 43.4874 18.5779 43.4874H41.2065C42.0249 43.4874 42.8097 43.1623 43.3884 42.5836C43.9671 42.0049 44.2922 41.22 44.2922 40.4017V23.9445C44.2922 23.5393 44.2124 23.138 44.0573 22.7637C43.9022 22.3893 43.6749 22.0491 43.3884 21.7626C43.1019 21.4761 42.7617 21.2488 42.3873 21.0937C42.0129 20.9386 41.6117 20.8588 41.2065 20.8588H37.6065V18.3285C37.6065 17.3737 37.2272 16.4581 36.5521 15.7829C35.8769 15.1078 34.9613 14.7285 34.0065 14.7285H25.7779ZM34.5208 20.8588V18.3285C34.5208 18.1921 34.4666 18.0613 34.3701 17.9649C34.2737 17.8684 34.1429 17.8142 34.0065 17.8142H25.7779C25.6415 17.8142 25.5107 17.8684 25.4142 17.9649C25.3178 18.0613 25.2636 18.1921 25.2636 18.3285V20.8588H34.5208Z"
      fill="white"
    />
  </svg>
);
export default SvgOrganizationEnterpriseEducationPlanSubscription;
