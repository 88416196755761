import { BaseSvgProps } from "./types";
const SvgUnlimitedPlanSubscription = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
  fill = "#0FCD5B",
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M30 60c16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30 0 46.57 13.431 60 30 60Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.688 28.061h4.996a26.795 26.795 0 0 1 3.197-9.826c-4.27 1.495-7.478 5.248-8.193 9.826ZM30 18.9a22.917 22.917 0 0 0-3.406 9.162h6.812A22.919 22.919 0 0 0 30 18.9Zm3.406 13.039A22.919 22.919 0 0 1 30 41.1a22.917 22.917 0 0 1-3.406-9.162h6.812Zm-10.722 0h-4.996c.715 4.578 3.924 8.331 8.193 9.826a26.796 26.796 0 0 1-3.197-9.826Zm11.435 9.826a26.796 26.796 0 0 0 3.197-9.826h4.996c-.715 4.578-3.924 8.331-8.193 9.826Zm8.193-13.704h-4.996a26.795 26.795 0 0 0-3.197-9.826c4.27 1.495 7.477 5.248 8.193 9.826ZM13.662 30c0-9.023 7.315-16.338 16.338-16.338S46.338 20.977 46.338 30 39.023 46.339 30 46.339 13.662 39.023 13.662 30Z"
      fill="#fff"
    />
  </svg>
);
export default SvgUnlimitedPlanSubscription;
