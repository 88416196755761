"use client";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IntervalSwitch } from ".";

interface IntervalContext {
  isAnnually: boolean;
  setIsAnnually: (arg: boolean) => void;
  interval: "yearly" | "monthly";
  togglePlanInterval: (value: boolean) => void;
}

const IntervalContext = React.createContext<IntervalContext | null>(null);

export const usePricingIntervalSwitch = () => {
  const context = React.useContext(IntervalContext);
  if (context === null) {
    throw new Error(
      "usePricingIntervalSwitch must be used within a IntervalContext",
    );
  }
  return context;
};

type ProviderWithSwitchProps = {
  isWithSwitch: true;
  children: React.ReactNode;
  isPreselectedAnnually?: boolean;
  monthlyLabel: string;
  annuallyLabel: string;
  annuallySave: string;
  onDark?: boolean;
};

type ProviderWithoutSwitchProps = {
  isWithSwitch: false;
  children: React.ReactNode;
  isPreselectedAnnually?: boolean;
  onDark?: boolean;
};

export const IntervalProvider = (
  props: ProviderWithSwitchProps | ProviderWithoutSwitchProps,
) => {
  const isPreselectedAnnually = props.isPreselectedAnnually || true;
  const [isAnnually, setIsAnnually] = useState<boolean>(isPreselectedAnnually);

  const interval = useMemo(
    () => (isAnnually ? "yearly" : "monthly"),
    [isAnnually],
  );

  const togglePlanInterval = useCallback(
    (value: boolean) => {
      if (value) {
        sessionStorage.setItem("isAnnually", "true");
      } else {
        sessionStorage.setItem("isAnnually", "false");
      }
      setIsAnnually(value);
    },
    [setIsAnnually],
  );

  useEffect(() => {
    if (sessionStorage && !sessionStorage.getItem("isAnnually")) {
      sessionStorage.setItem("isAnnually", "true");
      setIsAnnually(true);
    }

    if (sessionStorage && sessionStorage.getItem("isAnnually") === "true") {
      setIsAnnually(true);
    }
    if (sessionStorage && sessionStorage.getItem("isAnnually") === "false") {
      setIsAnnually(false);
    }
  }, []);

  return (
    <IntervalContext.Provider
      value={{
        isAnnually,
        setIsAnnually,
        interval,
        togglePlanInterval,
      }}
    >
      {props.isWithSwitch && (
        <IntervalSwitch
          isAnnually={isAnnually}
          togglePlanInterval={togglePlanInterval}
          monthlyLabel={props.monthlyLabel}
          annuallyLabel={props.annuallyLabel}
          annuallySave={props.annuallySave}
          classNames="justify-center"
          onDark={props.onDark || false}
        />
      )}
      {props.children}
    </IntervalContext.Provider>
  );
};
