"use client";

import { Switch } from "@headlessui/react";
import classnames from "classnames";
import { Typography } from "../../Typography";

type IntervalSwitchProps = {
  isAnnually: boolean;
  togglePlanInterval: (value: boolean) => void;
  monthlyLabel?: string;
  annuallyLabel?: string;
  annuallySave?: string;
  classNames?: string;
  size?: "sm" | "lg";
  onDark?: boolean;
};
export const IntervalSwitch = ({
  isAnnually,
  togglePlanInterval,
  monthlyLabel,
  annuallyLabel,
  annuallySave,
  classNames,
  size = "lg",
  onDark,
}: IntervalSwitchProps) => {
  return (
    <div className={classnames("flex", classNames)}>
      <Switch.Group as="div" className="flex items-center gap-2.5">
        {monthlyLabel && (
          <Switch.Label as="span" className="ml-3">
            <Typography.Body
              element="span"
              className={classnames("!text-lg !font-bold", {
                "text-invert-primary": !onDark,
                "text-primary": onDark,
                "!text-sm !font-normal": size === "sm",
              })}
            >
              {monthlyLabel}
            </Typography.Body>
          </Switch.Label>
        )}
        <Switch
          aria-label="switch"
          checked={isAnnually}
          onChange={togglePlanInterval}
          className={classnames(
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-positive focus-visible:ring-offset-2",
            {
              "!h-4 w-6": size === "sm",
              "bg-positive": isAnnually,
              "bg-tertiary": !isAnnually,
            },
          )}
        >
          <span
            aria-hidden="true"
            className={classnames(
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-primary shadow ring-0 transition duration-200 ease-in-out",
              {
                "!h-3 !w-3": size === "sm",
                "translate-x-0": !isAnnually,
                "translate-x-5": isAnnually && size === "lg",
                "translate-x-2": isAnnually && size === "sm",
              },
            )}
          />
        </Switch>
        {annuallyLabel && (
          <Switch.Label
            className=" flex flex-wrap items-center gap-x-2.5"
            as="span"
          >
            <Typography.Body
              element="span"
              className={classnames("!text-lg !font-bold", {
                "text-invert-primary": !onDark,
                "text-primary": onDark,
                "!text-sm !font-normal": size === "sm",
              })}
            >
              {annuallyLabel}
            </Typography.Body>
            {annuallySave && (
              <span className="flex flex-col justify-center bg-promo px-1 py-0.5 text-[10px] font-bold uppercase leading-3 text-neutral-900 max-sm:truncate">
                {annuallySave}
              </span>
            )}
          </Switch.Label>
        )}
      </Switch.Group>
    </div>
  );
};
