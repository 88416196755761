"use client";
import React from "react";
import { Action, ActionProps } from "./Action";
import { ActionWithMenu } from "./ActionWithMenu";
import { Menu, MenuProps } from "./Menu";
import classnames from "classnames";

export type ToolboxProps = {
  label?: string;
  children?: React.ReactNode;
  classNames?: string;
} & React.HTMLAttributes<HTMLElement>;

export type ToolboxStatic = {
  Action: React.FunctionComponent<ActionProps>;
  Menu: React.FunctionComponent<MenuProps>;
  ActionWithMenu: React.FunctionComponent<MenuProps>;
};

const Toolbox = ({ label, children, classNames }: ToolboxProps) => {
  return (
    <div className={classNames}>
      <label className="block text-center text-xs font-bold text-primary">
        {label}
      </label>
      <div
        className={classnames(
          "mt-2.5 flex h-16 items-center justify-center gap-2.5 rounded-[40px]  p-6",
          "bg-primary dark:bg-neutral-900",
        )}
      >
        {children}
      </div>
    </div>
  );
};

Toolbox.Action = Action;
Toolbox.Menu = Menu;
Toolbox.ActionWithMenu = ActionWithMenu;

export { Toolbox };
