import classnames from "classnames";

export type LogoDarkProps = {
  size: "small" | "medium" | "large";
  type: "primary" | "secondary" | "tertiary";
};

const LogoDark = ({ size = "large" }) => (
  <svg viewBox="0 0 343 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.2257 21.8765C34.6102 20.0773 33.6396 18.799 32.3139 18.0414C31.0355 17.2839 29.189 16.9051 26.7743 16.9051C24.3597 16.9051 22.4895 17.1892 21.1638 17.7574C19.8855 18.2782 19.2463 19.0594 19.2463 20.101C19.2463 20.8586 19.5304 21.4267 20.0985 21.8055C20.714 22.1842 21.8267 22.5157 23.4364 22.7998L34.9416 24.7173C41.8068 25.8536 46.7072 27.6764 49.6426 30.1858C52.6254 32.6478 54.1169 36.1751 54.1169 40.7677C54.1169 52.0361 45.1447 57.6703 27.2005 57.6703C10.1558 57.6703 1.08897 51.539 0 39.2763H17.9679C18.7255 43.206 21.8267 45.1709 27.2715 45.1709C33.0951 45.1709 36.0069 43.9162 36.0069 41.4069C36.0069 40.602 35.6044 39.9865 34.7996 39.5604C34.042 39.0869 32.811 38.7081 31.1065 38.424L21.945 37.0037C14.9378 35.9147 9.75334 34.0445 6.39175 31.3931C3.03016 28.6944 1.34937 25.096 1.34937 20.5981C1.34937 15.5321 3.64567 11.5787 8.23826 8.73789C12.8782 5.84977 19.2936 4.4057 27.4845 4.4057C35.1073 4.4057 41.0256 5.75507 45.2394 8.45381C49.5006 11.1052 51.9626 15.0823 52.6254 20.3851L35.2257 21.8765Z"
      fill="#EB0059"
    />
    <path
      d="M93.8639 5.68405H112.187V33.5237C112.187 41.762 109.962 47.846 105.511 51.7757C101.108 55.7055 94.29 57.6703 85.0575 57.6703C75.825 57.6703 68.9834 55.7055 64.5329 51.7757C60.1297 47.846 57.928 41.762 57.928 33.5237V5.68405H76.2511V35.0151C76.2511 37.8085 76.9376 39.8444 78.3106 41.1228C79.6837 42.4011 81.9326 43.0403 85.0575 43.0403C88.1824 43.0403 90.4313 42.4011 91.8043 41.1228C93.1774 39.8444 93.8639 37.8085 93.8639 35.0151V5.68405Z"
      fill="#EB0059"
    />
    <path
      d="M119.601 56.392V5.68405H147.582C162.307 5.68405 169.669 10.0636 169.669 18.8227C169.669 23.6046 167.018 27.1556 161.715 29.4756C165.03 30.5646 167.492 32.1033 169.101 34.0919C170.758 36.0331 171.587 38.4951 171.587 41.4779C171.587 46.3545 169.646 50.0712 165.763 52.6279C161.928 55.1373 156.318 56.392 148.932 56.392H119.601ZM146.872 24.9303C150.613 24.9303 152.483 23.6757 152.483 21.1663C152.483 19.8406 152.033 18.9174 151.133 18.3965C150.234 17.8284 148.742 17.5443 146.659 17.5443H136.219V24.9303H146.872ZM147.298 44.5317C149.808 44.5317 151.583 44.1766 152.625 43.4664C153.714 42.7562 154.258 41.6199 154.258 40.0575C154.258 38.4951 153.69 37.3824 152.554 36.7196C151.465 36.0094 149.666 35.6543 147.156 35.6543H136.219V44.5317H147.298Z"
      fill="#EB0059"
    />
    <path
      d="M227.235 56.392V31.6772L217.008 56.392H203.87L193.643 31.6772V56.392H177.237V5.68405H197.62L210.759 36.4355L223.897 5.68405H243.996V56.392H227.235Z"
      fill="#EB0059"
    />
    <path d="M251.936 56.392V5.68405H270.259V56.392H251.936Z" fill="#EB0059" />
    <path
      d="M315.509 56.392L304.572 41.5489L293.635 56.392H273.395L293.493 30.0437L274.886 5.68405H296.263L304.927 18.3255L314.16 5.68405H334.4L316.148 29.5466L336.886 56.392H315.509Z"
      fill="#EB0059"
    />
    <path
      d="M41.3397 17.8005C40.7242 16.0013 39.7536 14.723 38.4279 13.9654C37.1496 13.2079 35.3031 12.8291 32.8884 12.8291C30.4737 12.8291 28.6036 13.1132 27.2779 13.6813C25.9995 14.2021 25.3603 14.9834 25.3603 16.025C25.3603 16.7825 25.6444 17.3507 26.2126 17.7294C26.8281 18.1082 27.9407 18.4396 29.5505 18.7237L41.0556 20.6412C47.9209 21.7776 52.8212 23.6004 55.7567 26.1098C58.7395 28.5718 60.2309 32.0991 60.2309 36.6917C60.2309 47.9601 51.2588 53.5943 33.3145 53.5943C16.2698 53.5943 7.20301 47.4629 6.11404 35.2002H24.082C24.8395 39.13 27.9407 41.0949 33.3855 41.0949C39.2091 41.0949 42.1209 39.8402 42.1209 37.3308C42.1209 36.5259 41.7185 35.9104 40.9136 35.4843C40.1561 35.0109 38.9251 34.6321 37.2206 34.348L28.0591 32.9276C21.0518 31.8387 15.8674 29.9685 12.5058 27.3171C9.14421 24.6183 7.46341 21.02 7.46341 16.5221C7.46341 11.4561 9.75971 7.50264 14.3523 4.66186C18.9922 1.77373 25.4077 0.32967 33.5986 0.32967C41.2214 0.32967 47.1396 1.67904 51.3535 4.37778C55.6146 7.02918 58.0766 11.0063 58.7395 16.3091L41.3397 17.8005Z"
      fill="#150EEB"
    />
    <path
      d="M99.978 1.60802H118.301V29.4477C118.301 37.6859 116.076 43.7699 111.625 47.6997C107.222 51.6294 100.404 53.5943 91.1715 53.5943C81.939 53.5943 75.0975 51.6294 70.6469 47.6997C66.2437 43.7699 64.0421 37.6859 64.0421 29.4477V1.60802H82.3651V30.9391C82.3651 33.7325 83.0516 35.7684 84.4247 37.0468C85.7977 38.3251 88.0467 38.9643 91.1715 38.9643C94.2964 38.9643 96.5453 38.3251 97.9184 37.0468C99.2914 35.7684 99.978 33.7325 99.978 30.9391V1.60802Z"
      fill="#150EEB"
    />
    <path
      d="M125.715 52.3159V1.60802H153.696C168.421 1.60802 175.784 5.98756 175.784 14.7466C175.784 19.5286 173.132 23.0796 167.829 25.3996C171.144 26.4885 173.606 28.0273 175.215 30.0158C176.872 31.957 177.701 34.419 177.701 37.4018C177.701 42.2785 175.76 45.9952 171.877 48.5519C168.042 51.0613 162.432 52.3159 155.046 52.3159H125.715ZM152.986 20.8543C156.727 20.8543 158.597 19.5996 158.597 17.0903C158.597 15.7646 158.147 14.8413 157.247 14.3205C156.348 13.7524 154.856 13.4683 152.773 13.4683H142.333V20.8543H152.986ZM153.412 40.4557C155.922 40.4557 157.697 40.1006 158.739 39.3904C159.828 38.6802 160.372 37.5439 160.372 35.9815C160.372 34.419 159.804 33.3064 158.668 32.6435C157.579 31.9333 155.78 31.5782 153.27 31.5782H142.333V40.4557H153.412Z"
      fill="#150EEB"
    />
    <path
      d="M233.349 52.3159V27.6012L223.122 52.3159H209.984L199.757 27.6012V52.3159H183.352V1.60802H203.734L216.873 32.3595L230.011 1.60802H250.11V52.3159H233.349Z"
      fill="#150EEB"
    />
    <path d="M258.05 52.3159V1.60802H276.373V52.3159H258.05Z" fill="#150EEB" />
    <path
      d="M321.623 52.3159L310.686 37.4729L299.749 52.3159H279.509L299.607 25.9677L281 1.60802H302.377L311.041 14.2495L320.274 1.60802H340.514L322.262 25.4706L343 52.3159H321.623Z"
      fill="#150EEB"
    />
    <path
      d="M37.2637 19.8385C36.6482 18.0393 35.6776 16.761 34.3519 16.0034C33.0735 15.2459 31.227 14.8671 28.8124 14.8671C26.3977 14.8671 24.5275 15.1512 23.2018 15.7194C21.9235 16.2402 21.2843 17.0214 21.2843 18.063C21.2843 18.8205 21.5684 19.3887 22.1365 19.7675C22.752 20.1462 23.8647 20.4777 25.4744 20.7617L36.9796 22.6793C43.8448 23.8156 48.7452 25.6384 51.6806 28.1478C54.6635 30.6098 56.1549 34.1371 56.1549 38.7297C56.1549 49.9981 47.1827 55.6323 29.2385 55.6323C12.1938 55.6323 3.12698 49.501 2.03801 37.2383H20.0059C20.7635 41.168 23.8647 43.1329 29.3095 43.1329C35.1331 43.1329 38.0449 41.8782 38.0449 39.3688C38.0449 38.564 37.6425 37.9484 36.8376 37.5223C36.08 37.0489 34.849 36.6701 33.1446 36.386L23.983 34.9656C16.9758 33.8767 11.7914 32.0065 8.42976 29.3551C5.06817 26.6564 3.38738 23.058 3.38738 18.5601C3.38738 13.4941 5.68368 9.54066 10.2763 6.69988C14.9162 3.81175 21.3316 2.36768 29.5226 2.36768C37.1453 2.36768 43.0636 3.71706 47.2774 6.4158C51.5386 9.06719 54.0006 13.0443 54.6635 18.3471L37.2637 19.8385Z"
      fill="white"
    />
    <path
      d="M95.9019 3.64603H114.225V31.4857C114.225 39.7239 112 45.8079 107.549 49.7377C103.146 53.6674 96.328 55.6323 87.0955 55.6323C77.863 55.6323 71.0214 53.6674 66.5709 49.7377C62.1677 45.8079 59.9661 39.7239 59.9661 31.4857V3.64603H78.2891V32.9771C78.2891 35.7705 78.9756 37.8064 80.3486 39.0848C81.7217 40.3631 83.9706 41.0023 87.0955 41.0023C90.2204 41.0023 92.4693 40.3631 93.8424 39.0848C95.2154 37.8064 95.9019 35.7705 95.9019 32.9771V3.64603Z"
      fill="white"
    />
    <path
      d="M121.639 54.354V3.64603H149.62C164.345 3.64603 171.707 8.02557 171.707 16.7846C171.707 21.5666 169.056 25.1176 163.753 27.4376C167.068 28.5265 169.53 30.0653 171.139 32.0538C172.796 33.995 173.625 36.457 173.625 39.4399C173.625 44.3165 171.684 48.0332 167.801 50.5899C163.966 53.0993 158.356 54.354 150.97 54.354H121.639ZM148.91 22.8923C152.651 22.8923 154.521 21.6376 154.521 19.1283C154.521 17.8026 154.071 16.8793 153.171 16.3585C152.272 15.7904 150.78 15.5063 148.697 15.5063H138.257V22.8923H148.91ZM149.336 42.4937C151.846 42.4937 153.621 42.1386 154.663 41.4284C155.752 40.7182 156.296 39.5819 156.296 38.0195C156.296 36.457 155.728 35.3444 154.592 34.6816C153.503 33.9714 151.704 33.6163 149.194 33.6163H138.257V42.4937H149.336Z"
      fill="white"
    />
    <path
      d="M229.273 54.354V29.6392L219.046 54.354H205.908L195.681 29.6392V54.354H179.275V3.64603H199.658L212.797 34.3975L225.935 3.64603H246.034V54.354H229.273Z"
      fill="white"
    />
    <path d="M253.974 54.354V3.64603H272.297V54.354H253.974Z" fill="white" />
    <path
      d="M317.547 54.354L306.61 39.5109L295.673 54.354H275.433L295.531 28.0057L276.924 3.64603H298.301L306.965 16.2875L316.198 3.64603H336.438L318.186 27.5086L338.924 54.354H317.547Z"
      fill="white"
    />
  </svg>
);

export { LogoDark };
