"use client";
/* eslint-disable @typescript-eslint/ban-ts-comment */
import classnames from "classnames";
import { useCallback } from "react";
import { WithContext as ReactTags, Tag } from "react-tag-input";
import { Typography } from "../Typography";

const KeyCodes = {
  space: 32,
  tab: 9,
  comma: 188,
  enter: 13,
};

const delimiters = [
  KeyCodes.space,
  KeyCodes.tab,
  KeyCodes.comma,
  KeyCodes.enter,
];

export type Chip = {
  id: string;
  [key: string]: string | null | JSX.Element;
};

export type Suggestion = {
  id: string;
  [key: string]: string;
};

interface ChipInputProps {
  customDelimiters?: number[];
  placeholder?: string;
  renderTag?: any;
  className?: string;
  label?: string;
  onDark?: boolean;
  error?: string;
  tags: Chip[];
  suggestions?: Suggestion[];
  setTags: (tags: any) => void;
  labelField?: string;
  warning?: string;
}

const ChipInput = ({
  customDelimiters = delimiters,
  placeholder = "enter email",
  renderTag,
  className,
  label = "",
  onDark,
  error,
  tags,
  setTags,
  labelField,
  warning,
  suggestions = [],
}: ChipInputProps) => {
  const handleDelete = useCallback(
    (i: number) => {
      setTags(tags.filter((tag, index) => index !== i));
    },
    [setTags, tags],
  );

  const handleAddition = useCallback(
    (tag: Chip) => {
      if (renderTag) {
        tag = renderTag(tag);
      }
      if (!tag) return;

      setTags([...tags, tag]);
    },
    [renderTag, setTags, tags],
  );

  const handleDrag = useCallback(
    (tag: Chip, currPos: number, newPos: number) => {
      const newTags = tags.slice();

      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);

      // re-render
      setTags(newTags);
    },
    [setTags, tags],
  );

  const handleInputBlur = useCallback(
    (input: string) => {
      if (input !== "") {
        let tag: Chip = { id: input, user: input };
        if (renderTag) {
          tag = renderTag(tag);
        }
        if (!tag) return;
        setTags([...tags, tag]);
      }
    },
    [setTags, renderTag, tags],
  );

  return (
    <Typography.Body element="div" onDark={onDark} className={className}>
      {label}
      <ReactTags
        tags={tags as unknown as Tag[]}
        suggestions={suggestions as unknown as Tag[]}
        delimiters={customDelimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleInputBlur={handleInputBlur}
        handleDrag={handleDrag}
        labelField={labelField}
        autofocus={false}
        inputFieldPosition="inline"
        placeholder={tags && tags.length === 0 ? placeholder : ""}
        classNames={{
          tag: "@apply py-2 px-4 bg-neutral-200 min-h-11 rounded-full flex self-center font-normal items-center",
          remove: "@apply transparent border-0 ml-3 text-2xl leading-none	",
          tagInputField:
            "@apply p-1.5 border-transparent w-full focus-visible:border-none focus-visible:outline-none font-normal min-w-40",
          selected:
            "@apply border py-2.5 px-4 flex relative rounded mt-1 flex-row border-neutral-500 flex-wrap items-center gap-2.5 focus-within:ring-invert-primary focus-within:ring-2 focus-within:!ring-offset-4",
          tagInput: "@apply flex flex-1 border-neutral-500 font-normal",
          suggestions:
            "@apply absolute w-full -bottom-full rounded border-neutral-200 border-2 w-full bg-white left-0  py-2.5 px-3.5 ",
        }}
      />
      <Typography.ErrorMessage
        className={classnames(
          `invisible block min-h-6 pt-2`,
          (error || warning) && "!visible font-normal",
          warning && "!text-yellow-700",
        )}
      >
        {error && error}
        {warning && warning}
      </Typography.ErrorMessage>
    </Typography.Body>
  );
};

export { ChipInput };
