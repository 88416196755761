import { Dialog } from "@headlessui/react";
import React from "react";

export type ModalHeaderProps = {
  children?: React.ReactNode;
  className?: string;
  element?: React.ElementType;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  className,
  element = "header",
}) => {
  return (
    <Dialog.Title as={element} className={className}>
      {children}
    </Dialog.Title>
  );
};
