"use client";
import React from "react";
import classnames from "classnames";

export type PanelFooterProps = {
  children: React.ReactNode;
  classNames?: classnames.ArgumentArray;
} & React.HTMLAttributes<HTMLElement>;

const PanelFooter = ({ children, classNames }: PanelFooterProps) => {
  return (
    <div className={classnames("flex w-full flex-col gap-4 pt-7", classNames)}>
      {children}
    </div>
  );
};

export { PanelFooter };
