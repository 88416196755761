"use client";

import Lottie, { LottieRefCurrentProps } from "lottie-react";
import React, { useCallback, useEffect, useRef } from "react";
import classnames from "classnames";

type AnimationProps = {
  play: boolean;
  classNames?: string;
  animationData: Record<string, any>;
  loop?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;

export const Animation: React.FC<AnimationProps> = ({
  play,
  classNames,
  animationData,
  loop = true,
  ...other
}) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  const handleStart = useCallback(() => {
    if (lottieRef.current) {
      lottieRef.current.play();
    }
  }, []);

  const handleStop = useCallback(() => {
    if (lottieRef.current) {
      lottieRef.current.stop();
    }
  }, []);

  useEffect(() => {
    if (play) {
      handleStart();
    } else {
      handleStop();
    }
  }, [play]);

  useEffect(() => {
    return () => {
      if (lottieRef.current) {
        lottieRef.current.destroy();
      }
    };
  }, []);

  return (
    <span {...other} className={classnames("inline-block", classNames)}>
      <Lottie
        lottieRef={lottieRef}
        animationData={animationData}
        loop={loop}
        autoplay={false}
      />
    </span>
  );
};
