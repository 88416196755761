"use client";

import {
  PlanPrice as Price,
  formatPrice,
  usePricingIntervalSwitch,
} from "@submix/component-library";

const PlanPrice = ({
  annualPrice,
  monthlyPrice,
  onDark,
  smallPrice = false,
}: {
  annualPrice?: number;
  monthlyPrice?: number;
  onDark?: boolean;
  smallPrice?: boolean;
}) => {
  const { isAnnually } = usePricingIntervalSwitch();

  let priceVerbose = "";

  if (isAnnually && annualPrice) {
    priceVerbose = `${formatPrice(annualPrice / 12)}`;
  }
  if (!isAnnually && monthlyPrice) {
    priceVerbose = `${formatPrice(monthlyPrice)}`;
  }
  return (
    <>
      {priceVerbose && (
        <Price
          price={priceVerbose}
          interval={"/month"}
          onDark={onDark}
          smallPrice={smallPrice}
        />
      )}
    </>
  );
};

export default PlanPrice;
