import { AssistDark } from "./AssistDark";
import { AssistLight } from "./AssistLight";
import { LogoBaseDark } from "./LogoBaseDark";
import { LogoBaseLight } from "./LogoBaseLight";
import { LogoDark } from "./LogoDark";
import { LogoLight } from "./LogoLight";
import { PatchbayDark } from "./PatchbayDark";
import { PatchbayLight } from "./PatchbayLight";
import { SessionDark } from "./SessionDark";
import { SessionLight } from "./SessionLight";

export type LogoProps = {
  type?: "dark" | "light";
  // deprecate at some point
  base?: boolean;
  system?: "base" | "basic" | "session" | "patchbay" | "assist";
};

const Logo: React.FunctionComponent<LogoProps> = ({
  type = "dark",
  base,
  system = "basic",
}) => {
  let matchedSystem = system;
  if (base && !["session", "patchbay", "assist"].includes(system)) {
    matchedSystem = "base";
  }

  if (type === "light") {
    switch (matchedSystem) {
      case "assist":
        return <AssistLight />;
      case "base":
        return <LogoBaseLight />;
      case "basic":
        return <LogoLight />;
      case "patchbay":
        return <PatchbayLight />;
      case "session":
        return <SessionLight />;
      default:
        <LogoLight />;
    }
  }

  switch (matchedSystem) {
    case "assist":
      return <AssistDark />;
    case "base":
      return <LogoBaseDark />;
    case "basic":
      return <LogoDark />;
    case "patchbay":
      return <PatchbayDark />;
    case "session":
      return <SessionDark />;
    default:
      <LogoDark />;
  }

  return <LogoDark />;
};

export { Logo };
