"use client";

import classnames from "classnames";
import React, {
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from "react";

const baseClassName = `
  ease-in-out
  text-base
  rounded
  transition duration-400
  px-4
  py-2.5
  border
  border-neutral-500
  font-normal
  font-ui
  tracking-[0.01em]
  w-[100%]
  h-10
  hover:shadow-md
  disabled:bg-neutral-100
  focus-visible:outline-offset-transparent
  focus-visible:outline-offset-[3px]
  focus-visible:outline-2
  focus-visible:outline
`;

const onLightClassName = `
  text-invert-primary
  bg-primary  
  focus-visible:outline-invert-primary
  `;

const onDarkClassName = `
  text-neutral-50
  bg-neutral-900
  focus-visible:outline-primary
`;

// TBD shall this stay here

export type InputProps = {
  children?: React.ReactNode;
  className?: string;
  onDark?: boolean;
} & React.HTMLProps<HTMLInputElement>;

export type InputRef = {
  getValue: () => string | undefined;
  setValue: (value: string) => void;
  getValidity: () => ValidityState | undefined;
  clearValue: () => void;
  setFocusState?: () => void;
};

type InputComponentType = ForwardRefExoticComponent<
  PropsWithoutRef<InputProps> & RefAttributes<InputRef>
> & {
  classes?: string;
};

const Input: InputComponentType = forwardRef<InputRef, InputProps>(
  ({ children, className, onDark, ...other }, customRef) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const getValue = useCallback(() => inputRef?.current?.value, []);

    const setValue = useCallback((value: string) => {
      if (inputRef && inputRef.current) {
        inputRef.current.value = value;
      }
    }, []);

    const getValidity = useCallback(() => {
      return inputRef?.current?.validity;
    }, []);

    const clearValue = useCallback(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.value = "";
      }
    }, []);

    const setFocusState = useCallback(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    useImperativeHandle(
      customRef,
      () => ({ getValue, setValue, clearValue, getValidity, setFocusState }),
      [getValue, setValue, getValidity, clearValue, setFocusState],
    );

    return (
      <input
        // @ts-ignore
        ref={inputRef}
        className={classnames(baseClassName, className, {
          [onDarkClassName]: onDark,
          [onLightClassName]: !onDark,
        })}
        {...other}
      />
    );
  },
);

Input.classes = baseClassName;

export { Input };
