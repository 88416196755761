"use client";
import { Listbox as HeadlessListbox } from "@headlessui/react";
import classNames from "classnames";
import React, { ReactElement } from "react";
import MenuItem from "./MenuItem";

export type MenuProps = {
  label?: string | ReactElement;
  children: React.ReactNode;
  open?: boolean;
  className?: string;
  multiple?: boolean;
  selectedValues: any;
  setselectedValues: (value: any) => void;
  openButton: ReactElement;
  closeButton: ReactElement;
} & React.HTMLAttributes<HTMLElement>;

const PopupMenu = ({
  label,
  children,
  className,
  multiple = true,
  selectedValues,
  setselectedValues,
  openButton,
  closeButton,
}: MenuProps) => {
  return (
    <HeadlessListbox
      value={selectedValues}
      onChange={setselectedValues}
      multiple={multiple}
    >
      {({ open }) => (
        <>
          <HeadlessListbox.Label className="block text-sm font-medium leading-6 text-gray-900">
            {label}
          </HeadlessListbox.Label>
          {!open && (
            <HeadlessListbox.Button>{openButton}</HeadlessListbox.Button>
          )}

          <div
            className={classNames(
              className,
              "transition-opacity	 duration-150 ease-in",
              open ? "opacity-100" : "opacity-0",
            )}
          >
            <HeadlessListbox.Options className="absolute z-10 w-[412px] overflow-auto rounded-lg bg-white p-5 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-lg:max-w-[calc(100vw-20px)] sm:text-sm">
              <HeadlessListbox.Button className={"pb-7"}>
                {closeButton}
              </HeadlessListbox.Button>
              {children}
            </HeadlessListbox.Options>
          </div>
        </>
      )}
    </HeadlessListbox>
  );
};

PopupMenu.Item = MenuItem;

export { PopupMenu };
