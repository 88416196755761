"use client";
import { Listbox as HeadlessListbox, Transition } from "@headlessui/react";
import { FeatureCheckmark } from "@submix/component-library";
import classnames from "classnames";
import React, { Fragment, ReactElement, ReactNode } from "react";

export type MenuProps = {
  label: string;
  items: Array<MenuItem>;
  open?: boolean;
  className?: string;
  noItemsText?: string;
  pointLeft?: boolean;
  extraContent?: ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export type MenuItem = {
  description?: ReactElement | string;
  name: string;
  id: string;
};

export const Menu = ({
  label,
  items,
  open,
  className,
  noItemsText,
  pointLeft = false,
  extraContent,
}: MenuProps) => {
  return (
    <>
      <Transition
        show={open}
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={classnames(
            className,
            "absolute bottom-full z-10 mb-5 w-auto overflow-visible rounded bg-secondary py-2.5 text-base shadow-lg focus:outline-none sm:text-sm",
            {
              "right-[calc(100%-44px)]": pointLeft,
            },
          )}
        >
          <div
            className={classnames(
              "absolute -bottom-1 left-4 h-4 w-4 origin-center rotate-45 rounded-sm  bg-secondary",
              {
                "left-full -translate-x-[calc(100%+16px)]": pointLeft,
              },
            )}
          />
          <span className="p-2.5 font-bold text-neutral-900">{label}</span>

          <HeadlessListbox.Options className="custom-scrollbar max-h-60 overflow-y-auto pt-2.5">
            {items.map((option) => (
              <HeadlessListbox.Option
                key={option.id}
                className={({ active }) =>
                  classnames(
                    active ? " bg-secondary-alt" : "",
                    "relative cursor-pointer  select-none py-2.5  pl-9 pr-4 text-neutral-900",
                  )
                }
                value={option}
              >
                {({ selected }) => (
                  <>
                    <div className={classnames("flex items-center")}>
                      {selected && (
                        <span className="absolute inset-y-0 left-0 flex items-center p-2.5">
                          <FeatureCheckmark
                            height={16}
                            width={16}
                            strokeWidth={2}
                          />
                        </span>
                      )}
                      <span className={classnames("flex-1 truncate")}>
                        {option.name}
                      </span>
                      <span className={classnames("ml-4  truncate")}>
                        {option.description}
                      </span>
                    </div>
                  </>
                )}
              </HeadlessListbox.Option>
            ))}
            {items.length < 1 && noItemsText ? (
              <span className="block whitespace-nowrap p-2.5 !text-sm !leading-5 text-invert-primary">
                {noItemsText}
              </span>
            ) : null}
            {extraContent}
          </HeadlessListbox.Options>
        </div>
      </Transition>
    </>
  );
};
