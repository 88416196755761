import { BaseSvgProps } from "./types";
const SvgValidationCheckmarkOk = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
  fill = "none",
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="m1.628 14.473 4.357 5.6a1.594 1.594 0 0 0 2.489.049L22.372 3.304"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgValidationCheckmarkOk;
