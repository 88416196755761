import classnames from "classnames";
import { Disclosure, Transition } from "@headlessui/react";
import { BaseTypography } from "../Typography/types";
import { Typography } from "../Typography";

const baseClassName = `
text-neutral-900
bg-transparent
p-5
pt-0
rounded-b
`;

const AccordionPanel: React.FunctionComponent<BaseTypography> = ({
  children,
  className,
  onDark,
}) => {
  return (
    <Transition
      enter="transition duration-100 ease-out"
      enterFrom="opacity-0"
      enterTo=" opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom=" opacity-100"
      leaveTo="opacity-0"
    >
      <Disclosure.Panel className={classnames(className, baseClassName)}>
        <Typography.Body element="div" onDark={onDark}>
          {children}
        </Typography.Body>
      </Disclosure.Panel>
    </Transition>
  );
};

export { AccordionPanel };
