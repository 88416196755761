/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";
import { Typography, getTierIcon } from "@submix/component-library";
import classNames from "classnames";
import { Fragment } from "react";
import { usePricingIntervalSwitch } from "../IntervalSwitch/Provider";

const PlanHero = ({
  Image,
  currency,
  description,
  headerWrapperClassNames,
  isCurrentlyActive,
  isRecommended,
  price,
  priceClassName,
  src,
  title,
  titleClassName,
}: {
  src?: string;
  title: string;
  isCurrentlyActive?: boolean;
  isRecommended?: boolean;
  titleClassName: string;
  priceClassName: string;
  headerWrapperClassNames: string;
  currency: string;
  price: {
    monthly: {
      value?: number;
      oldPrice: number;
      note?: string;
    };
    annually: {
      value?: number;
      oldPrice: number;
      note?: string;
    };
  };
  description?: string;
  Image?: React.ComponentType<any>;
}) => {
  const { isAnnually } = usePricingIntervalSwitch();
  const isMonthly = !isAnnually;
  const isHighlighted = isCurrentlyActive || isRecommended;

  const monthlyPriceVerbose = price.monthly.value
    ? `${currency}${price.monthly.value}`
    : "No Price";
  const annualPriceVerbose = price.annually.value
    ? `${currency}${Number(price.annually.value / 12).toFixed(2)}`
    : "No Price";

  return (
    <Fragment>
      <div className="grid grid-rows-[1fr_auto_auto]">
        <div
          className={classNames(
            "flex flex-wrap items-center gap-2.5 overflow-hidden rounded-lg max-sm:flex-col",
            headerWrapperClassNames,
          )}
        >
          <div className="flex min-w-fit items-center ">
            {getTierIcon(title, 60, 60, "#8CE300")}
          </div>

          <Typography.Heading3
            className={classNames(
              "!font-headings !text-3xl !font-bold ",
              {
                "!text-neutral-50": isCurrentlyActive,
              },
              titleClassName,
            )}
          >
            {title}
          </Typography.Heading3>
        </div>
        {!!description && (
          <Typography.Body
            className={classNames(
              "pt-4 text-base font-light",
              isCurrentlyActive ? "!text-neutral-50" : "!text-neutral-700",
            )}
          >
            {description}
          </Typography.Body>
        )}
        {price && (
          <div className="flex flex-col pt-4">
            <Typography.Body className="flex flex-wrap items-end">
              <Typography.Body
                element="span"
                className={classNames(
                  "!font-headings text-5xl !font-bold !leading-none tracking-[-0.04em]",
                  {
                    "!text-neutral-50": isCurrentlyActive,
                    "!text-positive":
                      !isHighlighted &&
                      ((price.annually && price.annually.oldPrice > 0) ||
                        (price.monthly && price.monthly.oldPrice > 0)),
                  },
                  priceClassName,
                )}
              >
                {isMonthly ? monthlyPriceVerbose : annualPriceVerbose}
              </Typography.Body>
              <Typography.Body
                element="span"
                className={classNames(
                  "ml-2.5 !text-base",
                  isCurrentlyActive ? "!text-neutral-50" : "!text-neutral-700",
                  {
                    priceClassName,
                  },
                )}
              >
                /month
              </Typography.Body>
              {!isMonthly && (
                <Typography.Body
                  element="span"
                  className={classNames(
                    "!text-base",
                    isCurrentlyActive
                      ? "!text-neutral-50"
                      : "!text-neutral-700",
                    {
                      priceClassName,
                    },
                  )}
                >
                  <strong
                    className={
                      isCurrentlyActive
                        ? "!text-primary"
                        : "!text-invert-primary"
                    }
                  >{`${currency}${price.annually.value}`}</strong>{" "}
                  billed once a year
                </Typography.Body>
              )}
            </Typography.Body>
            {price.annually.oldPrice > 0 && price.monthly.oldPrice > 0 ? (
              <div>
                <Typography.Body
                  element="span"
                  className={classNames(
                    "!font-headings text-2xl !font-bold sm:text-2xl",
                    {
                      "!text-neutral-50": isHighlighted,
                      "text-gray-900": !isHighlighted,
                    },
                  )}
                >
                  Instead of{" "}
                </Typography.Body>
                <Typography.Body
                  element="span"
                  className={classNames(
                    "!font-headings text-2xl !font-bold line-through sm:text-2xl",
                    {
                      "!text-neutral-50": isHighlighted,
                      "text-gray-900": !isHighlighted,
                    },
                  )}
                >
                  {isMonthly
                    ? `${currency}${price.monthly.oldPrice}`
                    : `${currency}${price.annually.oldPrice}`}
                </Typography.Body>
              </div>
            ) : null}
            {price.monthly.note ||
              (price.annually.note && (
                <Typography.Body
                  className={classNames({
                    "!text-neutral-50": isHighlighted,
                    "text-gray-900": !isHighlighted,
                  })}
                >
                  {isMonthly && price.monthly.note && price.monthly.note}
                  {!isMonthly && price.annually.note && price.annually.note}
                </Typography.Body>
              ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default PlanHero;
