export const LinkClasses = {
  baseClassName: `
  bg-transparent
  border
  disabled:opacity-30
  ease-in-out
  focus-visible:outline-none
  focus-visible:ring-2
  focus-visible:ring-neutral-50
  focus-visible:ring-offset-2
  focus-visible:ring-offset-neutral-900
  font-semibold
  hover:border-neutral-50
  hover:shadow-lg 
  inline-block
  px-4
  py-2
  rounded 
  rounded-full
  shadow-none
  text-base
  text-neutral-50
  transition duration-400
  font-ui
`,
};
