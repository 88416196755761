"use client";
import classnames from "classnames";
import {
  addMonths,
  addYears,
  format,
  getDay,
  getDaysInMonth,
  isEqual,
  subMonths,
  subYears,
} from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "../Button";
import { ChevronArrowDown } from "../Icons";
import { Input } from "../Input";
import { Label } from "../Typography/Label";

export type DatePickerProps = {
  selectedDate?: Date;
  setSelectedDate:
    | React.Dispatch<React.SetStateAction<Date>>
    | ((value: Date) => void);
  label?: string;
  className?: string;
  name?: string;
};
type DatepickerType = "date" | "month";

const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const DatePicker: React.FC<DatePickerProps> = ({
  selectedDate,
  setSelectedDate,
  label,
  className,
  name,
}) => {
  const [dayCount, setDayCount] = useState<Array<number>>([]);
  const [blankDays, setBlankDays] = useState<Array<number>>([]);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [datepickerHeaderDate, setDatepickerHeaderDate] = useState(
    selectedDate || new Date(),
  );
  const [type, setType] = useState<DatepickerType>("date");

  const wrapperRef = useRef<HTMLDivElement>(null);

  const decrement = () => {
    switch (type) {
      case "date":
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
      case "month":
        setDatepickerHeaderDate((prev) => subYears(prev, 1));
        break;
    }
  };

  const increment = () => {
    switch (type) {
      case "date":
        setDatepickerHeaderDate((prev) => addMonths(prev, 1));
        break;
      case "month":
        setDatepickerHeaderDate((prev) => addYears(prev, 1));
        break;
    }
  };

  const isDaySelected = (date: number) =>
    selectedDate &&
    isEqual(
      new Date(
        datepickerHeaderDate.getFullYear(),
        datepickerHeaderDate.getMonth(),
        date,
      ),
      new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
      ),
    );

  const setDateValue = (date: number) => () => {
    setSelectedDate(
      new Date(
        datepickerHeaderDate.getFullYear(),
        datepickerHeaderDate.getMonth(),
        date,
      ),
    );
    setShowDatepicker(false);
  };

  const getDaysTemplate = useCallback((date: Date) => {
    const daysInMonth = getDaysInMonth(date);

    // Find what is start week day of month (Sun = 0, ..., Sat = 6)
    const startWeekDayOfMonth = getDay(
      new Date(date.getFullYear(), date.getMonth(), 1),
    );

    const blankdaysArray = [];
    for (let i = 1; i <= startWeekDayOfMonth; i++) {
      blankdaysArray.push(i);
    }

    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }
    setBlankDays(blankdaysArray);
    setDayCount(daysArray);
  }, []);

  const isSelectedMonth = (month: number) =>
    selectedDate &&
    isEqual(
      new Date(selectedDate.getFullYear(), month, selectedDate.getDate()),
      new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
      ),
    );

  const setMonthValue = (month: number) => () => {
    setDatepickerHeaderDate(
      new Date(
        datepickerHeaderDate.getFullYear(),
        month,
        datepickerHeaderDate.getDate(),
      ),
    );
    setType("date");
  };

  const toggleDatepicker = () => setShowDatepicker((prev) => !prev);

  const showMonthPicker = () => setType("month");

  const handleOutsideClick = (e: Event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
      // Outside click
      setShowDatepicker(false);
    }
  };

  useEffect(() => {
    getDaysTemplate(datepickerHeaderDate);
  }, [datepickerHeaderDate]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      className={classnames(
        "relative  w-fit max-w-full rounded text-invert-primary",
        className,
      )}
    >
      {label && (
        <Label className="mb-1 block text-sm text-neutral-900">{label}</Label>
      )}
      <div className="relative  !font-light">
        <Input
          readOnly
          aria-readonly={true}
          type="text"
          name={name}
          className="!invisible !hidden w-full cursor-pointer rounded !border-none !bg-neutral-100 py-3 pl-4 pr-12 leading-none text-neutral-900 focus-within:!outline-none hover:!shadow-none focus-visible:outline-none"
          placeholder="Select date"
          value={selectedDate && format(selectedDate, "MMMM dd yyyy")}
        />
        <Button
          onClick={toggleDatepicker}
          className={classnames(
            "h-11 w-full justify-between rounded bg-secondary !px-4 !py-2.5 !text-sm !font-light hover:bg-secondary-alt hover:shadow-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-invert-primary focus-visible:ring-offset-4",
            {
              "!bg-neutral-400": showDatepicker,
            },
          )}
          color="transparent"
          variant="secondary"
          type="button"
          rightIcon={
            <ChevronArrowDown
              stroke="black"
              width={12}
              height={12}
              strokeWidth={4}
              className={classnames(
                "transition-transform duration-200 ease-in-out",
                showDatepicker && "rotate-180",
              )}
            />
          }
        >
          {selectedDate && format(selectedDate, "MMM dd yyyy")}
        </Button>
      </div>

      {showDatepicker && (
        <div className="max-w-screen absolute left-0 top-full z-10 mt-2.5 w-[290px] rounded bg-secondary p-4 !font-light  shadow-lg">
          <div className="mb-4 flex items-center justify-between border-b border-neutral-500 pb-4 ">
            <Button
              className="!h-auto 
            !w-auto rotate-90 cursor-pointer border-none bg-transparent !p-0 hover:!bg-transparent hover:shadow-none focus-visible:!shadow-none focus-visible:!outline-none focus-visible:!ring-2 focus-visible:!ring-invert-primary focus-visible:!ring-offset-2"
              onClick={decrement}
              color="transparent"
              iconOnly={
                <ChevronArrowDown
                  width={16}
                  height={16}
                  stroke="#000"
                  strokeWidth={3}
                />
              }
              type="button"
            />
            {type === "date" && (
              <Button
                skipStyles
                color="black"
                onClick={showMonthPicker}
                className="text-md flex   cursor-pointer items-center gap-2 rounded text-neutral-900 focus-visible:!shadow-none focus-visible:!outline-none focus-visible:!ring-2 focus-visible:!ring-invert-primary focus-visible:!ring-offset-2"
              >
                <p className="text-center leading-tight">
                  {format(datepickerHeaderDate, "MMMM yyyy")}
                </p>
              </Button>
            )}
            {type === "month" && (
              <Button
                skipStyles
                color="black"
                tabIndex={0}
                onClick={() => setType("date")}
                className="text-md flex cursor-pointer items-center gap-2 rounded text-neutral-900  focus-visible:!shadow-none focus-visible:!outline-none focus-visible:!ring-2 focus-visible:!ring-invert-primary focus-visible:!ring-offset-2"
              >
                <p className="text-center leading-tight">
                  {format(datepickerHeaderDate, "yyyy")}
                </p>
              </Button>
            )}
            <Button
              color="transparent"
              className="!h-auto !w-auto -rotate-90 cursor-pointer border-none bg-transparent !p-0 hover:!bg-transparent hover:shadow-none focus-visible:!shadow-none focus-visible:!outline-none focus-visible:!ring-2 focus-visible:!ring-invert-primary focus-visible:!ring-offset-2"
              onClick={increment}
              iconOnly={
                <ChevronArrowDown
                  width={16}
                  height={16}
                  stroke="#000"
                  strokeWidth={3}
                />
              }
              type="button"
            />
          </div>
          {type === "date" && (
            <>
              <div className="-mx-1 mb-3 flex flex-wrap">
                {DAYS.map((day, i) => (
                  <div key={i} style={{ width: "14.26%" }} className="px-1">
                    <div className="text-center text-sm uppercase text-tertiary ">
                      {day}
                    </div>
                  </div>
                ))}
              </div>
              <div className="-mx-1 flex flex-wrap gap-y-1">
                {blankDays.map((_, i) => (
                  <div
                    key={i}
                    style={{ width: "14.26%" }}
                    className="border border-transparent p-1 text-center text-sm"
                  ></div>
                ))}
                {dayCount.map((d, i) => (
                  <div
                    key={i}
                    style={{ width: "14.26%" }}
                    className="flex items-center justify-center px-1"
                  >
                    <Button
                      skipStyles
                      color="black"
                      tabIndex={0}
                      onClick={setDateValue(d)}
                      className={` flex h-8 w-8  cursor-pointer items-center justify-center rounded-full text-sm leading-loose transition duration-100 ease-in-out focus-visible:!shadow-none focus-visible:!outline-none focus-visible:!ring-2 focus-visible:!ring-invert-primary focus-visible:!ring-offset-2 ${
                        isDaySelected(d)
                          ? "bg-neutral-900 text-neutral-50"
                          : "text-invert-primary hover:bg-neutral-100"
                      }`}
                    >
                      {d}
                    </Button>
                  </div>
                ))}
              </div>
            </>
          )}
          {type === "month" && (
            <div className="flex flex-wrap gap-y-2.5">
              {Array(12)
                .fill(null)
                .map((_, i) => (
                  <Button
                    skipStyles
                    color="black"
                    tabIndex={0}
                    key={i}
                    onClick={setMonthValue(i)}
                    className="rounded  focus-visible:!shadow-none focus-visible:!outline-none focus-visible:!ring-2 focus-visible:!ring-invert-primary focus-visible:!ring-offset-2"
                    style={{ width: "50%" }}
                  >
                    <div
                      className={`text-md cursor-pointer rounded text-center ${
                        isSelectedMonth(i)
                          ? "bg-neutral-900 text-neutral-50"
                          : "text-neutral-900 hover:bg-neutral-100"
                      }`}
                    >
                      <p className="leading-loose">
                        {format(
                          new Date(
                            datepickerHeaderDate.getFullYear(),
                            i,
                            datepickerHeaderDate.getDate(),
                          ),
                          "MMMM",
                        )}
                      </p>
                    </div>
                  </Button>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
