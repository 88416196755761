import classnames from "classnames";

export type SessionDarkProps = {
  size: "small" | "medium" | "large";
  type: "primary" | "secondary" | "tertiary";
};

const SessionDark = ({ size = "large" }) => (
  <svg viewBox="0 0 389 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M89.3027 28.8353C88.0166 28.8353 86.976 29.9499 86.976 31.3274V39.6685C86.976 41.046 88.0166 42.1606 89.3027 42.1606C90.5887 42.1606 91.6294 41.046 91.6294 39.6685V31.3274C91.6294 29.9499 90.5887 28.8353 89.3027 28.8353Z"
      fill="white"
    />
    <path
      d="M81.4727 25.5595C80.1867 25.5595 79.146 26.6741 79.146 28.0516V42.9394C79.146 44.3169 80.1867 45.4315 81.4727 45.4315C82.7588 45.4315 83.7994 44.3169 83.7994 42.9394V28.0516C83.7994 26.6741 82.7588 25.5595 81.4727 25.5595Z"
      fill="white"
    />
    <path
      d="M73.6382 18.195C72.3522 18.195 71.3115 19.3096 71.3115 20.6871V50.3085C71.3115 51.686 72.3521 52.8006 73.6382 52.8006C74.9243 52.8006 75.9649 51.686 75.9649 50.3085V20.6871C75.9649 19.3096 74.9243 18.195 73.6382 18.195Z"
      fill="white"
    />
    <path
      d="M65.8037 10.1977C64.5177 10.1977 63.477 11.3123 63.477 12.6899V58.3008C63.477 59.6783 64.5177 60.7929 65.8037 60.7929C67.0898 60.7929 68.1304 59.6783 68.1304 58.3008V12.6899C68.1304 11.3124 67.0898 10.1977 65.8037 10.1977Z"
      fill="white"
    />
    <path
      d="M57.9738 24.0353C56.6877 24.0353 55.6471 25.1499 55.6471 26.5274V44.462C55.6471 45.8395 56.6877 46.9541 57.9738 46.9541C59.2598 46.9541 60.3005 45.8395 60.3005 44.462V26.5274C60.3005 25.1499 59.2599 24.0353 57.9738 24.0353Z"
      fill="white"
    />
    <path
      d="M50.1393 17.0262C48.8532 17.0262 47.8126 18.1408 47.8126 19.5183V51.4761C47.8126 52.8536 48.8532 53.9682 50.1393 53.9682C51.4253 53.9682 52.466 52.8536 52.466 51.4761V19.5183C52.466 18.1408 51.4254 17.0262 50.1393 17.0262Z"
      fill="white"
    />
    <path
      d="M42.3048 3.85524C41.0187 3.85524 39.9781 4.96985 39.9781 6.34738V64.6482C39.9781 66.0257 41.0187 67.1404 42.3048 67.1404C43.5909 67.1404 44.6315 66.0258 44.6315 64.6482V6.34738C44.6315 4.96989 43.5909 3.85524 42.3048 3.85524Z"
      fill="white"
    />
    <path
      d="M34.6157 7.75406C33.3388 7.75406 32.2981 8.85895 32.289 10.2316L31.9982 57.0613C31.9974 57.1803 32.0044 57.2975 32.0188 57.4122L31.9982 60.7296C31.9891 62.1022 33.0206 63.2266 34.3067 63.2363H34.3249C35.6019 63.2363 36.6425 62.1314 36.6516 60.7588L36.9425 13.9291C36.9432 13.8101 36.9362 13.6929 36.9219 13.5782L36.9425 10.2608C36.9515 8.88818 35.92 7.7638 34.6339 7.75406H34.6157Z"
      fill="white"
    />
    <path
      d="M26.6403 27.4236C25.3543 27.4236 24.3136 28.5382 24.3136 29.9157V41.0847C24.3136 42.4622 25.3543 43.5769 26.6403 43.5769C27.9264 43.5769 28.9671 42.4623 28.9671 41.0847V29.9157C28.9671 28.5382 27.9264 27.4236 26.6403 27.4236Z"
      fill="white"
    />
    <path
      d="M18.8059 19.4653C17.5198 19.4653 16.4791 20.58 16.4791 21.9575V49.0381C16.4791 50.4156 17.5198 51.5303 18.8059 51.5303C20.0919 51.5303 21.1326 50.4157 21.1326 49.0381V21.9575C21.1326 20.58 20.0919 19.4653 18.8059 19.4653Z"
      fill="white"
    />
    <path
      d="M10.9714 16.8613C9.68531 16.8613 8.64465 17.9759 8.64465 19.3534V51.6427C8.64465 53.0202 9.68531 54.1348 10.9714 54.1348C12.2574 54.1348 13.2981 53.0202 13.2981 51.6427V19.3534C13.2981 17.976 12.2574 16.8613 10.9714 16.8613Z"
      fill="white"
    />
    <path
      d="M3.14141 27.4237C1.85535 27.4237 0.814697 28.5383 0.814697 29.9159V41.0849C0.814697 42.4624 1.85535 43.577 3.14141 43.577C4.42746 43.577 5.46811 42.4624 5.46811 41.0849V29.9159C5.46811 28.5384 4.42746 27.4237 3.14141 27.4237Z"
      fill="white"
    />
    <path
      d="M128.166 6.98206C127.905 6.26304 127.494 5.75216 126.933 5.44941C126.392 5.14667 125.61 4.9953 124.588 4.9953C123.565 4.9953 122.774 5.10883 122.212 5.33589C121.671 5.54402 121.401 5.85623 121.401 6.2725C121.401 6.57525 121.521 6.80231 121.761 6.95368C122.022 7.10505 122.493 7.2375 123.175 7.35103L128.045 8.11736C130.952 8.57147 133.026 9.29996 134.269 10.3028C135.532 11.2867 136.163 12.6964 136.163 14.5318C136.163 19.0351 132.365 21.2868 124.768 21.2868C117.552 21.2868 113.714 18.8364 113.253 13.9357H120.859C121.18 15.5062 122.493 16.2915 124.798 16.2915C127.264 16.2915 128.496 15.7901 128.496 14.7872C128.496 14.4655 128.326 14.2196 127.985 14.0493C127.664 13.8601 127.143 13.7087 126.422 13.5952L122.543 13.0275C119.577 12.5923 117.382 11.8449 115.959 10.7853C114.535 9.70677 113.824 8.26873 113.824 6.47118C113.824 4.44657 114.796 2.86662 116.74 1.73133C118.705 0.577109 121.421 0 124.888 0C128.115 0 130.621 0.539265 132.405 1.6178C134.209 2.6774 135.251 4.26682 135.532 6.38603L128.166 6.98206Z"
      fill="white"
    />
    <path
      d="M152.99 0.510883H160.747V11.6368C160.747 14.9291 159.805 17.3605 157.921 18.931C156.057 20.5015 153.171 21.2868 149.262 21.2868C145.353 21.2868 142.457 20.5015 140.573 18.931C138.709 17.3605 137.777 14.9291 137.777 11.6368V0.510883H145.534V12.2328C145.534 13.3492 145.824 14.1628 146.406 14.6737C146.987 15.1846 147.939 15.44 149.262 15.44C150.585 15.44 151.537 15.1846 152.118 14.6737C152.699 14.1628 152.99 13.3492 152.99 12.2328V0.510883Z"
      fill="white"
    />
    <path
      d="M163.886 20.7759V0.510883H175.732C181.966 0.510883 185.083 2.26113 185.083 5.76162C185.083 7.6727 183.96 9.09182 181.715 10.019C183.118 10.4542 184.161 11.0691 184.842 11.8638C185.544 12.6396 185.894 13.6235 185.894 14.8156C185.894 16.7645 185.073 18.2499 183.429 19.2716C181.805 20.2745 179.43 20.7759 176.303 20.7759H163.886ZM175.431 8.2025C177.015 8.2025 177.807 7.70108 177.807 6.69824C177.807 6.16843 177.616 5.79946 177.235 5.59133C176.854 5.36427 176.223 5.25074 175.341 5.25074H170.921V8.2025H175.431ZM175.612 16.036C176.674 16.036 177.426 15.8941 177.867 15.6103C178.328 15.3265 178.558 14.8724 178.558 14.2479C178.558 13.6235 178.318 13.1789 177.837 12.914C177.376 12.6302 176.614 12.4882 175.552 12.4882H170.921V16.036H175.612Z"
      fill="white"
    />
    <path
      d="M209.453 20.7759V10.8988L205.124 20.7759H199.561L195.232 10.8988V20.7759H188.287V0.510883H196.916L202.478 12.8004L208.04 0.510883H216.549V20.7759H209.453Z"
      fill="white"
    />
    <path d="M219.91 20.7759V0.510883H227.667V20.7759H219.91Z" fill="white" />
    <path
      d="M246.824 20.7759L242.194 14.844L237.564 20.7759H228.995L237.504 10.246L229.626 0.510883H238.676L242.344 5.56294L246.253 0.510883H254.822L247.095 10.0474L255.874 20.7759H246.824Z"
      fill="white"
    />
    <path
      d="M143.441 40.2424C142.496 37.8986 141.04 36.2164 139.074 35.1957C137.146 34.175 134.425 33.6647 130.909 33.6647C126.902 33.6647 123.802 34.1939 121.609 35.2524C119.455 36.3109 118.377 37.823 118.377 39.7888C118.377 41.1119 118.699 42.2082 119.341 43.0776C120.022 43.9093 121.061 44.5898 122.46 45.119C123.896 45.6483 125.806 46.083 128.187 46.4232L136.012 47.5573C140.7 48.2756 144.159 49.6176 146.389 51.5833C148.658 53.5113 149.792 56.1575 149.792 59.522C149.792 63.2267 148.204 66.1187 145.028 68.1978C141.853 70.2392 137.43 71.2599 131.76 71.2599C125.484 71.2599 120.683 70.2014 117.357 68.0844C114.03 65.9674 112.121 62.7542 111.629 58.4446H117.413C117.943 61.1286 119.379 63.1133 121.723 64.3986C124.067 65.6839 127.412 66.3266 131.76 66.3266C135.691 66.3266 138.715 65.7406 140.832 64.5687C142.949 63.359 144.008 61.6579 144.008 59.4653C144.008 57.6129 143.29 56.1575 141.853 55.099C140.416 54.0406 138.073 53.2656 134.822 52.7741L127.79 51.7535C124.01 51.1864 121.023 50.4115 118.831 49.4286C116.676 48.4457 115.107 47.1982 114.124 45.6861C113.179 44.1361 112.707 42.2271 112.707 39.9589C112.707 36.4432 114.313 33.7025 117.527 31.7367C120.74 29.7331 125.201 28.7314 130.909 28.7314C134.425 28.7314 137.354 29.1094 139.698 29.8654C142.08 30.5837 144.008 31.7178 145.482 33.2677C146.956 34.8177 148.072 36.8212 148.828 39.2784L143.441 40.2424Z"
      fill="white"
    />
    <path
      d="M159.502 57.0837C159.578 60.108 160.58 62.5084 162.508 64.2852C164.473 66.0619 167.101 66.9503 170.39 66.9503C175.871 66.9503 179.236 65.3059 180.483 62.017H185.586C183.999 68.1033 178.858 71.1465 170.163 71.1465C165.173 71.1465 161.26 69.7856 158.425 67.0637C155.59 64.3419 154.172 60.5994 154.172 55.8362C154.172 52.7741 154.815 50.0523 156.1 47.6707C157.423 45.2891 159.313 43.4179 161.771 42.057C164.228 40.696 167.082 40.0156 170.333 40.0156C175.285 40.0156 179.141 41.3198 181.901 43.9282C184.66 46.4988 186.04 50.109 186.04 54.7588V57.0837H159.502ZM180.823 53.0577C180.785 50.2224 179.859 48.0299 178.045 46.4799C176.23 44.93 173.641 44.155 170.276 44.155C167.365 44.155 164.946 44.9489 163.018 46.5366C161.09 48.1244 159.956 50.298 159.616 53.0577H180.823Z"
      fill="white"
    />
    <path
      d="M214.161 48.6347C213.594 47.1226 212.517 46.0074 210.929 45.2891C209.379 44.5331 207.243 44.155 204.521 44.155C201.8 44.155 199.645 44.5331 198.057 45.2891C196.469 46.0452 195.675 47.0659 195.675 48.3512C195.675 49.6365 196.224 50.6005 197.32 51.2431C198.454 51.8858 200.552 52.4339 203.614 52.8876L207.357 53.4546C210.683 53.946 213.216 54.5509 214.955 55.2692C216.732 55.9496 217.979 56.838 218.698 57.9343C219.454 59.0306 219.832 60.4482 219.832 62.1871C219.832 68.16 214.993 71.1465 205.315 71.1465C200.665 71.1465 197.055 70.3715 194.485 68.8216C191.914 67.2338 190.345 64.8522 189.778 61.6768H194.825C195.921 65.1925 199.38 66.9503 205.202 66.9503C208.264 66.9503 210.627 66.5534 212.29 65.7595C213.991 64.9657 214.842 63.8316 214.842 62.3572C214.842 61.0719 214.237 60.108 213.027 59.4653C211.817 58.8226 209.568 58.2556 206.279 57.7642L202.82 57.2538C199.645 56.7624 197.188 56.1575 195.449 55.4393C193.748 54.721 192.538 53.8137 191.82 52.7174C191.101 51.5833 190.742 50.1657 190.742 48.4646C190.742 45.8184 191.952 43.7581 194.371 42.2838C196.828 40.7717 200.212 40.0156 204.521 40.0156C208.755 40.0156 212.006 40.6393 214.275 41.8868C216.581 43.1343 218.168 45.1757 219.038 48.011L214.161 48.6347Z"
      fill="white"
    />
    <path
      d="M247.838 48.6347C247.271 47.1226 246.194 46.0074 244.606 45.2891C243.056 44.5331 240.921 44.155 238.199 44.155C235.477 44.155 233.322 44.5331 231.734 45.2891C230.147 46.0452 229.353 47.0659 229.353 48.3512C229.353 49.6365 229.901 50.6005 230.997 51.2431C232.131 51.8858 234.229 52.4339 237.291 52.8876L241.034 53.4546C244.361 53.946 246.893 54.5509 248.632 55.2692C250.409 55.9496 251.657 56.838 252.375 57.9343C253.131 59.0306 253.509 60.4482 253.509 62.1871C253.509 68.16 248.67 71.1465 238.993 71.1465C234.343 71.1465 230.733 70.3715 228.162 68.8216C225.591 67.2338 224.023 64.8522 223.455 61.6768H228.502C229.598 65.1925 233.057 66.9503 238.879 66.9503C241.941 66.9503 244.304 66.5534 245.967 65.7595C247.668 64.9657 248.519 63.8316 248.519 62.3572C248.519 61.0719 247.914 60.108 246.704 59.4653C245.495 58.8226 243.245 58.2556 239.957 57.7642L236.498 57.2538C233.322 56.7624 230.865 56.1575 229.126 55.4393C227.425 54.721 226.215 53.8137 225.497 52.7174C224.779 51.5833 224.419 50.1657 224.419 48.4646C224.419 45.8184 225.629 43.7581 228.049 42.2838C230.506 40.7717 233.889 40.0156 238.199 40.0156C242.433 40.0156 245.684 40.6393 247.952 41.8868C250.258 43.1343 251.846 45.1757 252.715 48.011L247.838 48.6347Z"
      fill="white"
    />
    <path
      d="M259.117 70.2392V40.9229H264.448V70.2392H259.117ZM258.947 35.5359V29.752H264.618V35.5359H258.947Z"
      fill="white"
    />
    <path
      d="M304.187 55.496C304.187 58.558 303.488 61.2799 302.089 63.6614C300.728 66.0052 298.743 67.8387 296.135 69.1618C293.527 70.4849 290.483 71.1465 287.005 71.1465C283.641 71.1465 280.692 70.5416 278.16 69.3319C275.627 68.1222 273.661 66.3455 272.262 64.0017C270.864 61.6579 270.164 58.8794 270.164 55.6661C270.164 52.604 270.845 49.9011 272.206 47.5573C273.604 45.1757 275.608 43.3234 278.216 42.0003C280.825 40.6771 283.868 40.0156 287.346 40.0156C290.71 40.0156 293.659 40.6204 296.192 41.8301C298.724 43.0398 300.69 44.8166 302.089 47.1604C303.488 49.5042 304.187 52.2827 304.187 55.496ZM298.573 55.6094C298.573 52.0559 297.571 49.2963 295.568 47.3305C293.602 45.3647 290.824 44.3819 287.232 44.3819C283.603 44.3819 280.787 45.3647 278.783 47.3305C276.78 49.2585 275.778 51.9992 275.778 55.5527C275.778 59.1062 276.761 61.8658 278.727 63.8316C280.73 65.7973 283.528 66.7802 287.119 66.7802C290.748 66.7802 293.564 65.8162 295.568 63.8883C297.571 61.9225 298.573 59.1629 298.573 55.6094Z"
      fill="white"
    />
    <path
      d="M334.978 70.2392V52.3205C334.978 49.5987 334.317 47.6329 332.993 46.4232C331.708 45.1757 329.61 44.552 326.699 44.552C325.149 44.552 323.599 44.8544 322.049 45.4592C320.537 46.0263 319.233 46.8391 318.137 47.8975C317.192 48.767 316.473 49.7499 315.982 50.8462C315.491 51.9047 315.245 53.0199 315.245 54.1918V70.2392H309.915V40.9229H315.188V46.3665C318.401 42.1326 322.692 40.0156 328.06 40.0156C332.105 40.0156 335.148 40.9796 337.19 42.9075C339.269 44.7977 340.308 47.614 340.308 51.3565V70.2392H334.978Z"
      fill="white"
    />
    <path
      d="M369.811 48.6347C369.244 47.1226 368.167 46.0074 366.579 45.2891C365.029 44.5331 362.893 44.155 360.171 44.155C357.45 44.155 355.295 44.5331 353.707 45.2891C352.119 46.0452 351.325 47.0659 351.325 48.3512C351.325 49.6365 351.874 50.6005 352.97 51.2431C354.104 51.8858 356.202 52.4339 359.264 52.8876L363.007 53.4546C366.333 53.946 368.866 54.5509 370.605 55.2692C372.382 55.9496 373.629 56.838 374.348 57.9343C375.104 59.0306 375.482 60.4482 375.482 62.1871C375.482 68.16 370.643 71.1465 360.965 71.1465C356.315 71.1465 352.705 70.3715 350.135 68.8216C347.564 67.2338 345.995 64.8522 345.428 61.6768H350.475C351.571 65.1925 355.03 66.9503 360.852 66.9503C363.914 66.9503 366.277 66.5534 367.94 65.7595C369.641 64.9657 370.492 63.8316 370.492 62.3572C370.492 61.0719 369.887 60.108 368.677 59.4653C367.467 58.8226 365.218 58.2556 361.929 57.7642L358.47 57.2538C355.295 56.7624 352.838 56.1575 351.099 55.4393C349.397 54.721 348.188 53.8137 347.47 52.7174C346.751 51.5833 346.392 50.1657 346.392 48.4646C346.392 45.8184 347.602 43.7581 350.021 42.2838C352.478 40.7717 355.862 40.0156 360.171 40.0156C364.405 40.0156 367.656 40.6393 369.925 41.8868C372.231 43.1343 373.818 45.1757 374.688 48.011L369.811 48.6347Z"
      fill="white"
    />
    <path
      d="M377.732 29.9146H375.629V28.9588H380.918V29.9146H378.815V35.3305H377.732V29.9146ZM381.842 28.9588H383.18L385.235 34.088L387.321 28.9588H388.643V35.3305H387.672V30.5358L385.681 35.3305H384.773L382.813 30.5517V35.3305H381.842V28.9588Z"
      fill="white"
    />
  </svg>
);

export { SessionDark };
