"use client";
import React, { useMemo, useRef } from "react";
import { Menu, MenuProps } from "./Menu";
import { Action, ActionProps } from "./Action";

export type ActionWithMenuProps = Omit<
  ActionProps,
  "isMenu" | "children" | "isIdle" | "rounded"
> &
  Pick<MenuProps, "noItemsText" | "items" | "label"> & {
    children?: React.ReactNode;
    extraContent?: React.ReactNode;
  };

const ActionWithMenu: React.FC<ActionWithMenuProps> = ({
  selectedItem,
  icon,
  items,
  onSelectItem,
  onButtonClick,
  label,
  noItemsText,
  disabled,
  children,
  isWarning,
  extraContent,
  numberIndicator,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const selectedItemReference = useMemo(
    () => items.find((t) => t.id === selectedItem?.id),
    [selectedItem, items],
  );

  // If component is on the right side of screen menu should point to left and vice versa
  const shouldMenuPointLeft = useMemo(() => {
    if (
      wrapperRef.current &&
      wrapperRef.current.getBoundingClientRect().x > window.innerWidth / 2
    ) {
      return true;
    } else {
      return false;
    }
  }, [wrapperRef.current]);

  return (
    <div ref={wrapperRef}>
      <Action
        icon={icon}
        isMenu
        selectedItem={selectedItemReference}
        onSelectItem={onSelectItem}
        onButtonClick={onButtonClick}
        disabled={disabled}
        isWarning={isWarning}
        numberIndicator={numberIndicator}
      >
        <>
          <Menu
            label={label}
            items={items}
            noItemsText={noItemsText}
            pointLeft={shouldMenuPointLeft}
            disabled={disabled}
            extraContent={extraContent}
          />
          {children}
        </>
      </Action>
    </div>
  );
};

export { ActionWithMenu };
