import {
  IntroPlanSubscription,
  OrganizationEnterpriseEducationPlanSubscription,
  ProPlanSubscription,
  UnlimitedPlanSubscription,
} from "@submix/component-library";

export const getTierIcon = (
  planName: string,
  height: string | number = "100%",
  width: string | number = "100%",
  fill?: string,
) => {
  if (planName === "" || planName === undefined) {
    return <></>;
  }
  let plan = planName.toLowerCase();
  if (plan.includes("_")) {
    plan = planName.split("_")[0] || "";
  }
  switch (plan) {
    case "pro":
      return (
        <UnlimitedPlanSubscription height={height} width={width} fill={fill} />
      );
    case "standard":
      return <ProPlanSubscription height={height} width={width} fill={fill} />;
    case "intro":
      return (
        <IntroPlanSubscription height={height} width={width} fill={fill} />
      );
    case "enterprise":
      return (
        <OrganizationEnterpriseEducationPlanSubscription
          height={height}
          width={width}
          fill={fill}
        />
      );
    default:
      return <></>;
  }
};
