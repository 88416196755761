"use client";
import classnames from "classnames";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import {
  CheckboxChecked,
  CheckboxEmpty,
  CheckboxError,
  RadioChecked,
  RadioEmpty,
  RadioError,
} from "../Icons";
import { Body } from "../Typography/Body";

const baseClassName = `select-none`;

export type CheckboxProps = {
  children?: React.ReactNode;
  className?: string;
  isRadio?: boolean;
  value?: string;
  name?: string;
  onDark?: boolean;
  showError?: boolean;
  checked?: boolean;
  readOnly?: boolean;
  indeterminate?: boolean;
} & React.HTMLAttributes<HTMLInputElement>;

export type CheckboxRef = {
  getValue: () => string | undefined;
  getChecked: () => boolean | undefined;
  setValue: (value: string) => void;
  clearValue: () => void;
  setChecked: (value: boolean) => void;
};

const Checkbox = forwardRef<CheckboxRef, CheckboxProps>(
  (
    {
      children,
      onDark,
      className,
      isRadio,
      value,
      name,
      showError,
      checked,
      readOnly,
      indeterminate = false,
      ...other
    },
    customRef,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.indeterminate = indeterminate;
      }
    }, [inputRef, indeterminate]);

    const getValue = useCallback(() => inputRef?.current?.value, []);
    const getChecked = useCallback(() => inputRef?.current?.checked, []);

    const setValue = useCallback((value: string) => {
      if (inputRef && inputRef.current) {
        inputRef.current.value = value;
      }
    }, []);

    const setChecked = useCallback((value: boolean) => {
      if (inputRef && inputRef.current) {
        inputRef.current.checked = value;
      }
    }, []);

    const clearValue = useCallback(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.value = "";
      }
    }, []);

    useImperativeHandle(
      customRef,
      () => ({ getValue, setValue, clearValue, getChecked, setChecked }),
      [getValue, setValue, clearValue, getChecked, setChecked],
    );

    const checkedIcon = isRadio ? (
      <RadioChecked
        fill={onDark ? "#000" : "#fff"}
        stroke={onDark ? "#fff" : "#000"}
      />
    ) : (
      <CheckboxChecked />
    );
    const emptyIcon = isRadio ? <RadioEmpty /> : <CheckboxEmpty />;
    const errorIcon = isRadio ? <RadioError /> : <CheckboxError />;

    return (
      <label className="justify-space-between flex cursor-pointer overflow-hidden">
        <input
          ref={inputRef}
          className={classnames("peer invisible h-0 w-0")}
          type={!isRadio ? "checkbox" : "radio"}
          value={value}
          name={name}
          checked={checked}
          readOnly={readOnly}
          {...other}
        />
        <div className="mr-2.5 hidden w-[24px] peer-checked:flex peer-checked:items-center">
          {checkedIcon}
        </div>
        <div className="mr-2.5 flex w-[24px] items-center peer-checked:hidden">
          {showError && !getChecked() ? errorIcon : emptyIcon}
        </div>
        <Body
          element="div"
          onDark={onDark}
          className={classnames(className, baseClassName)}
        >
          {children}
        </Body>
      </label>
    );
  },
);

export { Checkbox };
