"use client";
import { Combobox as HeadlessComobox } from "@headlessui/react";
import classnames from "classnames";
import { forwardRef, useState } from "react";
import ComboboxContent from "./ComboboxContent";
import { ComboboxRef } from "./types";

export type ComboboxOption = {
  name: string;
  id: string;
};

export type ComboboxProps = {
  options: Array<ComboboxOption>;
  className?: string;
  noOptionsMessage?: string;
  error?: string;
  label: string;
  onDark?: boolean;
  invalid?: boolean;
  onOptionChange?: (value: ComboboxOption | null) => void;
  multiple?: boolean;
  clearOnClick?: boolean;
} & React.HTMLProps<HTMLInputElement>;

const Combobox = forwardRef<ComboboxRef, ComboboxProps>(
  (
    {
      options,
      as,
      error,
      label,
      noOptionsMessage = "No match",
      className,
      onDark,
      invalid = false,
      onOptionChange,
      multiple = false,
      clearOnClick = false,
      ...other
    },
    customRef,
  ) => {
    const [selectedValue, setSelectedValue] = useState<
      ComboboxOption | undefined
    >(options.find((t) => t.name === other.defaultValue) || undefined);
    const [selectedValues, setSelectedValues] = useState<
      ComboboxOption[] | undefined
    >([]);

    return (
      <div className={classnames(className, "")}>
        {!multiple ? (
          <HeadlessComobox
            nullable
            value={selectedValue}
            name={other.name}
            onChange={(c: any | undefined) => {
              setSelectedValue(c);

              if (onOptionChange) {
                onOptionChange(c);
              }
            }}
          >
            <ComboboxContent
              customRef={customRef}
              onDark={onDark}
              label={label}
              options={options}
              noOptionsMessage={noOptionsMessage}
              error={error}
              invalid={invalid}
              other={other}
              selectedValue={selectedValue}
              clearOnClick={clearOnClick}
            />
          </HeadlessComobox>
        ) : (
          <HeadlessComobox
            multiple
            value={selectedValues}
            onChange={(c: any | null) => {
              setSelectedValues(c);
              if (onOptionChange) {
                onOptionChange(c);
              }
            }}
          >
            <ComboboxContent
              customRef={customRef}
              onDark={onDark}
              label={label}
              options={options}
              noOptionsMessage={noOptionsMessage}
              error={error}
              other={other}
              isMultiple={multiple}
              invalid={invalid}
              selectedValues={selectedValues}
              clearOnClick={clearOnClick}
            />
          </HeadlessComobox>
        )}
      </div>
    );
  },
);
export { Combobox };
