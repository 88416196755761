import { BaseSvgProps } from "./types";
const SvgRadioChecked = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <rect
      x={3}
      y={3.724}
      width={14}
      height={14}
      rx={7}
      fill="#fff"
      stroke={stroke}
      strokeWidth={6}
    />
  </svg>
);
export default SvgRadioChecked;
