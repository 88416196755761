import classnames from "classnames";

export type AssistLightProps = {
  size: "small" | "medium" | "large";
  type: "primary" | "secondary" | "tertiary";
};

const AssistLight = ({ size = "large" }) => (
  <svg viewBox="0 0 195 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5362 6.98646C15.2756 6.26744 14.8647 5.75655 14.3034 5.45381C13.7622 5.15106 12.9805 4.99969 11.9583 4.99969C10.936 4.99969 10.1443 5.11322 9.58303 5.34028C9.04184 5.54842 8.77125 5.86062 8.77125 6.2769C8.77125 6.57964 8.89151 6.8067 9.13204 6.95807C9.39262 7.10945 9.86365 7.2419 10.5452 7.35543L15.4159 8.12175C18.3223 8.57587 20.3969 9.30435 21.6396 10.3072C22.9024 11.2911 23.5338 12.7008 23.5338 14.5362C23.5338 19.0395 19.7354 21.2912 12.1387 21.2912C4.92277 21.2912 1.08431 18.8408 0.623291 13.9401H8.23005C8.55076 15.5106 9.86365 16.2959 12.1687 16.2959C14.6342 16.2959 15.8669 15.7945 15.8669 14.7916C15.8669 14.4699 15.6965 14.224 15.3558 14.0537C15.035 13.8644 14.5139 13.7131 13.7923 13.5995L9.91376 13.0319C6.94723 12.5967 4.75239 11.8493 3.32925 10.7897C1.90612 9.71116 1.19455 8.27312 1.19455 6.47557C1.19455 4.45097 2.16669 2.87101 4.11098 1.73572C6.0753 0.581503 8.79129 0.00439453 12.2589 0.00439453C15.486 0.00439453 17.9916 0.54366 19.7755 1.62219C21.5795 2.6818 22.6218 4.27121 22.9024 6.39043L15.5362 6.98646Z"
      fill="black"
    />
    <path
      d="M40.3608 0.515277H48.1179V11.6412C48.1179 14.9335 47.1758 17.3649 45.2916 18.9354C43.4275 20.5059 40.5412 21.2912 36.6326 21.2912C32.7239 21.2912 29.8276 20.5059 27.9434 18.9354C26.0793 17.3649 25.1473 14.9335 25.1473 11.6412V0.515277H32.9043V12.2372C32.9043 13.3536 33.195 14.1672 33.7763 14.6781C34.3575 15.189 35.3096 15.4444 36.6326 15.4444C37.9555 15.4444 38.9076 15.189 39.4889 14.6781C40.0701 14.1672 40.3608 13.3536 40.3608 12.2372V0.515277Z"
      fill="black"
    />
    <path
      d="M51.2565 20.7803V0.515277H63.1026C69.3363 0.515277 72.4532 2.26552 72.4532 5.76601C72.4532 7.67709 71.3307 9.09621 69.0858 10.0234C70.4889 10.4586 71.5312 11.0735 72.2127 11.8682C72.9142 12.644 73.265 13.6279 73.265 14.82C73.265 16.7689 72.4432 18.2543 70.7996 19.276C69.176 20.2789 66.8008 20.7803 63.6739 20.7803H51.2565ZM62.8019 8.2069C64.3854 8.2069 65.1772 7.70548 65.1772 6.70263C65.1772 6.17283 64.9868 5.80386 64.6059 5.59572C64.2251 5.36866 63.5937 5.25513 62.7118 5.25513H58.292V8.2069H62.8019ZM62.9823 16.0404C64.0447 16.0404 64.7963 15.8985 65.2373 15.6147C65.6983 15.3309 65.9288 14.8768 65.9288 14.2523C65.9288 13.6279 65.6883 13.1833 65.2073 12.9184C64.7462 12.6345 63.9846 12.4926 62.9222 12.4926H58.292V16.0404H62.9823Z"
      fill="black"
    />
    <path
      d="M96.8238 20.7803V10.9032L92.4943 20.7803H86.932L82.6025 10.9032V20.7803H75.6572V0.515277H84.2862L89.8484 12.8048L95.4107 0.515277H103.919V20.7803H96.8238Z"
      fill="black"
    />
    <path d="M107.281 20.7803V0.515277H115.038V20.7803H107.281Z" fill="black" />
    <path
      d="M134.195 20.7803L129.565 14.8484L124.934 20.7803H116.365L124.874 10.2504L116.997 0.515277H126.047L129.715 5.56734L133.623 0.515277H142.192L134.465 10.0518L143.245 20.7803H134.195Z"
      fill="black"
    />
    <path
      d="M39.786 69.4503L34.4558 58.2795H12.2842L7.0107 69.4503H1L20.6198 28.9632H26.4037L46.0235 69.4503H39.786ZM23.3984 34.9739L14.439 53.6297H32.3577L23.3984 34.9739Z"
      fill="black"
    />
    <path
      d="M72.004 47.8459C71.437 46.3337 70.3596 45.2185 68.7719 44.5003C67.2219 43.7442 65.0861 43.3662 62.3642 43.3662C59.6424 43.3662 57.4876 43.7442 55.8999 44.5003C54.3122 45.2563 53.5183 46.277 53.5183 47.5623C53.5183 48.8476 54.0664 49.8116 55.1627 50.4543C56.2968 51.0969 58.3949 51.6451 61.4569 52.0987L65.1995 52.6658C68.5261 53.1572 71.0589 53.762 72.7979 54.4803C74.5746 55.1608 75.8221 56.0491 76.5404 57.1454C77.2965 58.2417 77.6745 59.6593 77.6745 61.3983C77.6745 67.3712 72.8357 70.3576 63.1581 70.3576C58.5083 70.3576 54.8981 69.5827 52.3275 68.0327C49.7569 66.445 48.188 64.0634 47.621 60.8879H52.6677C53.764 64.4036 57.223 66.1615 63.0447 66.1615C66.1067 66.1615 68.4694 65.7645 70.1328 64.9707C71.8339 64.1768 72.6845 63.0427 72.6845 61.5684C72.6845 60.2831 72.0796 59.3191 70.8699 58.6765C69.6602 58.0338 67.4109 57.4668 64.1221 56.9753L60.6631 56.465C57.4876 55.9735 55.0304 55.3687 53.2915 54.6504C51.5903 53.9322 50.3806 53.0249 49.6624 51.9286C48.9441 50.7945 48.585 49.3769 48.585 47.6757C48.585 45.0295 49.7947 42.9693 52.2141 41.4949C54.6713 39.9828 58.0547 39.2267 62.3642 39.2267C66.5982 39.2267 69.8493 39.8505 72.1174 41.098C74.4234 42.3455 76.0112 44.3869 76.8806 47.2221L72.004 47.8459Z"
      fill="black"
    />
    <path
      d="M105.681 47.8459C105.114 46.3337 104.037 45.2185 102.449 44.5003C100.899 43.7442 98.7633 43.3662 96.0415 43.3662C93.3197 43.3662 91.1649 43.7442 89.5772 44.5003C87.9894 45.2563 87.1956 46.277 87.1956 47.5623C87.1956 48.8476 87.7437 49.8116 88.84 50.4543C89.9741 51.0969 92.0722 51.6451 95.1342 52.0987L98.8767 52.6658C102.203 53.1572 104.736 53.762 106.475 54.4803C108.252 55.1608 109.499 56.0491 110.218 57.1454C110.974 58.2417 111.352 59.6593 111.352 61.3983C111.352 67.3712 106.513 70.3576 96.8354 70.3576C92.1856 70.3576 88.5754 69.5827 86.0048 68.0327C83.4342 66.445 81.8653 64.0634 81.2983 60.8879H86.345C87.4413 64.4036 90.9003 66.1615 96.722 66.1615C99.784 66.1615 102.147 65.7645 103.81 64.9707C105.511 64.1768 106.362 63.0427 106.362 61.5684C106.362 60.2831 105.757 59.3191 104.547 58.6765C103.338 58.0338 101.088 57.4668 97.7993 56.9753L94.3404 56.465C91.1649 55.9735 88.7077 55.3687 86.9687 54.6504C85.2676 53.9322 84.0579 53.0249 83.3396 51.9286C82.6214 50.7945 82.2623 49.3769 82.2623 47.6757C82.2623 45.0295 83.472 42.9693 85.8914 41.4949C88.3486 39.9828 91.7319 39.2267 96.0415 39.2267C100.275 39.2267 103.527 39.8505 105.795 41.098C108.101 42.3455 109.688 44.3869 110.558 47.2221L105.681 47.8459Z"
      fill="black"
    />
    <path
      d="M116.96 69.4503V40.134H122.29V69.4503H116.96ZM116.79 34.7471V28.9632H122.461V34.7471H116.79Z"
      fill="black"
    />
    <path
      d="M151.993 47.8459C151.426 46.3337 150.349 45.2185 148.761 44.5003C147.211 43.7442 145.075 43.3662 142.353 43.3662C139.631 43.3662 137.477 43.7442 135.889 44.5003C134.301 45.2563 133.507 46.277 133.507 47.5623C133.507 48.8476 134.055 49.8116 135.152 50.4543C136.286 51.0969 138.384 51.6451 141.446 52.0987L145.189 52.6658C148.515 53.1572 151.048 53.762 152.787 54.4803C154.564 55.1608 155.811 56.0491 156.529 57.1454C157.286 58.2417 157.664 59.6593 157.664 61.3983C157.664 67.3712 152.825 70.3576 143.147 70.3576C138.497 70.3576 134.887 69.5827 132.317 68.0327C129.746 66.445 128.177 64.0634 127.61 60.8879H132.657C133.753 64.4036 137.212 66.1615 143.034 66.1615C146.096 66.1615 148.458 65.7645 150.122 64.9707C151.823 64.1768 152.674 63.0427 152.674 61.5684C152.674 60.2831 152.069 59.3191 150.859 58.6765C149.649 58.0338 147.4 57.4668 144.111 56.9753L140.652 56.465C137.477 55.9735 135.019 55.3687 133.281 54.6504C131.579 53.9322 130.37 53.0249 129.651 51.9286C128.933 50.7945 128.574 49.3769 128.574 47.6757C128.574 45.0295 129.784 42.9693 132.203 41.4949C134.66 39.9828 138.044 39.2267 142.353 39.2267C146.587 39.2267 149.838 39.8505 152.106 41.098C154.412 42.3455 156 44.3869 156.87 47.2221L151.993 47.8459Z"
      fill="black"
    />
    <path
      d="M182.268 68.94C180 69.6961 177.58 70.0741 175.01 70.0741C171.797 70.0741 169.434 69.2991 167.922 67.7492C166.41 66.1993 165.654 63.7421 165.654 60.3776V44.3302H159.926V40.134H165.654V32.1387H170.984V40.134H181.417V44.3302H170.984V60.8312C170.984 64.0823 172.628 65.7078 175.917 65.7078C177.732 65.7078 179.565 65.4054 181.417 64.8006L182.268 68.94Z"
      fill="black"
    />
    <path
      d="M183.771 29.9189H181.668V28.9632H186.956V29.9189H184.854V35.3348H183.771V29.9189ZM187.88 28.9632H189.218L191.273 34.0923L193.36 28.9632H194.682V35.3348H193.71V30.5402L191.719 35.3348H190.811L188.852 30.5561V35.3348H187.88V28.9632Z"
      fill="black"
    />
  </svg>
);

export { AssistLight };
