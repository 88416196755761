import { BaseSvgProps } from "./types";
const SvgCheckboxEmpty = ({
  width,
  height,
  stroke = "#8E8E8E",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H18C18.8284 0.5 19.5 1.17157 19.5 2V18C19.5 18.8284 18.8284 19.5 18 19.5H2C1.17157 19.5 0.5 18.8284 0.5 18V2Z"
      fill="none"
    />
    <path
      d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H18C18.8284 0.5 19.5 1.17157 19.5 2V18C19.5 18.8284 18.8284 19.5 18 19.5H2C1.17157 19.5 0.5 18.8284 0.5 18V2Z"
      stroke={stroke || "#8E8E8E"}
      stroke-linecap="round"
    />
  </svg>
);
export default SvgCheckboxEmpty;
